import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AppDispatch, useAppSelector } from 'core/redux';
import PartnerGroups from 'core/redux/reducers/partnerGroups';
import { PartnerGroup } from 'types';
import { useDispatch } from 'react-redux';
import {
  deletePartnerGroup,
  fetchPartnerGroups,
  updatePartnerGroup,
} from 'core/redux/thunks';
import { ColDef } from 'ag-grid-community';
import AppGrid from './AppGrid';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useModalManager } from './modal-manager';
import { ModalIDs } from 'constants/modals';
import { DEFAULT_LIST_ID } from 'constants/redux';
import { UpdateGroupDialogProps } from './modals/UpdateGroupDialog';

const ActionsMenu: React.FC<{ data: PartnerGroup }> = ({ data }) => {
  const buttonRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpenState] = useState(false);
  const { openModal } = useModalManager();
  const handleOpen = useCallback(() => setOpenState(true), [setOpenState]);
  const handleClose = useCallback(
    () => setOpenState(false),
    [setOpenState],
  );

  const group = useAppSelector((state) =>
    PartnerGroups.selectors.selectById(state, data.id),
  );

  const handleDelete = useCallback(async () => {
    handleClose();
    const result = await openModal<unknown, boolean>(
      ModalIDs.ConfirmDeletionModal,
      {},
    );

    if (result) {
      dispatch(deletePartnerGroup({ id: data.id }, DEFAULT_LIST_ID));
    }
  }, [openModal, data, handleClose, dispatch]);
  const handleUpdate = useCallback(async () => {
    handleClose();
    await openModal<UpdateGroupDialogProps, void>(
      ModalIDs.UpdateGroupDialog,
      { group, updateThunk: updatePartnerGroup, id: data.id },
    );
  }, [handleClose, openModal, data, group]);
  return (
    <>
      <IconButton
        ref={buttonRef}
        aria-haspopup="menu"
        aria-controls="actions-menu"
        aria-expanded={open}
        onClick={handleOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        open={open}
        anchorEl={buttonRef.current}
        onClose={handleClose}
      >
        <MenuItem onClick={handleUpdate}>Update</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

const defaultColDef: ColDef<PartnerGroup> = {
  resizable: true,
  sortable: false,
};

const getColumnDefs = () => {
  const columnDefs: ColDef<PartnerGroup>[] = [
    {
      headerName: 'ID',
      field: 'id',
    },
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
    },
    {
      headerName: 'Updated At',
      field: 'updatedAt',
    },
    {
      headerName: 'Actions',
      cellRenderer: ActionsMenu,
    },
  ];

  return columnDefs;
};

const GroupsGrid: React.FC = () => {
  const rowData = useAppSelector(
    (state) =>
      PartnerGroups.selectors.selectEntitiesFromList(
        state,
        DEFAULT_LIST_ID,
      ) as PartnerGroup[],
  );
  const listState = useAppSelector((state) =>
    PartnerGroups.selectors.selectList(state, DEFAULT_LIST_ID),
  );
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (rowData.length) {
      return;
    }
    dispatch(fetchPartnerGroups({ page: 1 }, DEFAULT_LIST_ID));
  }, [dispatch, rowData]);

  return (
    <AppGrid
      rowData={rowData}
      defaultColDef={defaultColDef}
      columnDefs={getColumnDefs()}
      isLoading={!listState.isReady}
    />
  );
};

export default GroupsGrid;
