import { createEntityReducer } from '../createEntityReducer';
import { IdentifiableChunk } from '../types';
import { Flat, UserGroupJsonApiEntity } from '@one-vision/json-api-parser';

export type FlatUserGroup = Flat<UserGroupJsonApiEntity>;

const UserGroups = createEntityReducer<FlatUserGroup, 'id', 'userGroup'>({
  entityName: 'userGroup',
  entityIdProperty: 'id',
  idSelector: (userGroup) => userGroup.id,
});

export type UserGroupChunk = IdentifiableChunk<FlatUserGroup, 'id'>;

export default UserGroups;
