import { createEntityReducer } from '../createEntityReducer';
import { IdentifiableChunk } from '../types';
import {
  Flat,
  UserGroupRuleJsonApiEntity,
} from '@one-vision/json-api-parser';

export type FlatUserGroupRule = Flat<UserGroupRuleJsonApiEntity>;

const UserGroupRules = createEntityReducer<
  FlatUserGroupRule,
  'id',
  'userGroupRule'
>({
  entityName: 'userGroupRule',
  entityIdProperty: 'id',
  idSelector: (userGroupRule) => userGroupRule.id,
});

export type UserGroupRuleChunk = IdentifiableChunk<
  FlatUserGroupRule,
  'id'
>;

export default UserGroupRules;
