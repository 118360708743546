import React from 'react';
import { SvgIcon } from '@mui/material';

interface Props {
  width?: string;
  height?: string;
  viewBox?: string;
  fontSize?: string;
}

const LogoLetterIcon: React.FC<Props> = ({
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
  fontSize = '2.4rem',
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      htmlColor="#B1B1B2"
      sx={{
        fontSize,
      }}
    >
      <g clipPath="url(#clip0_10408_25228)">
        <path
          d="M17.9969 24H6.00308C2.68721 24 0 21.3128 0 17.9969V6.00308C0 2.68721 2.68721 0 6.00308 0H17.9969C21.3128 0 24 2.68721 24 6.00308V17.9969C24 21.3128 21.3128 24 17.9969 24Z"
          fill="#A259FF"
        />
        <path
          d="M9.94764 14.1756V7.58086C9.94764 7.3713 9.77506 7.21106 9.57784 7.21106H7.85211C7.72884 7.21106 7.60557 7.27269 7.54394 7.3713L6.48385 8.96144C6.31127 9.2203 6.49617 9.55312 6.80434 9.55312H7.26043C7.46998 9.55312 7.65488 9.7257 7.65488 9.94758V17.4668C7.65488 17.6764 7.82745 17.849 8.037 17.849H10.5147C10.6626 17.849 10.7982 17.775 10.8721 17.6641L17.5162 7.85204C17.7011 7.56853 17.5039 7.19873 17.1711 7.19873H15.4577C15.3221 7.19873 15.1865 7.27269 15.1125 7.38363L10.4284 14.3112C10.2805 14.5331 9.94764 14.4222 9.94764 14.1756Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10408_25228">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default LogoLetterIcon;
