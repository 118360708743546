import { DEFAULT_INPUT_DATE_FORMAT } from './dates';

export const VALID_PHONE = `Please enter a valid phone number. Valid formats: +1 (212) 000-0000`;
export const VALID_EMAIL =
  'Please enter a valid email address. Valid format: email@mail.com';

export const PHONE_PATTERN_REG = /^\+1 \(\d{1,3}\) \d{3}-\d{4}$/;

export const PHONE_PATTERN = '+1 (###) ###-####';

export const VALID_DATE = `Please enter a valid date. Valid format: ${DEFAULT_INPUT_DATE_FORMAT}`;
