import { createTheme } from '@mui/material';
import { components } from './components';
import { typography } from './typography';
import { palette } from './palette';
import { transitions } from './transitions';
export { CUSTOM_CLASSES } from './components';

export const configureTheme = () => {
  const theme = createTheme({
    components,
    typography,
    palette,
    transitions,
  });

  return theme;
};
