import React from 'react';
import { Box, InputAdornment, TextField, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NewPartnerButton from './NewPartnerButton';
import { PAGE_HEADER_HEIGHT } from 'constants/style';

interface Props {
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PartnersGridHeader: React.FC<Props> = ({ onSearchChange }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flex: '0 1 auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderBottom: `3px solid ${theme.palette.black.black20}`,
        width: '100%',
        height: PAGE_HEADER_HEIGHT,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginRight: theme.spacing(8),
          columnGap: theme.spacing(2),
          padding: theme.spacing(2, 0),
        }}
      >
        <TextField
          variant="standard"
          placeholder="Filter results"
          margin="none"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={onSearchChange}
        />
        <NewPartnerButton />
      </Box>
    </Box>
  );
};

export default PartnersGridHeader;
