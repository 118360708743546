import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import UserGroups from 'core/redux/reducers/userGroups';

export const deleteUserGroup =
  (options: { id: string }, listId?: string): AppThunk =>
  async (dispatch) => {
    try {
      await apiClient.deleteUserGroup(options);
      dispatch(UserGroups.actions.remove(options, listId));
    } catch (error) {
      console.error(error);
    }
  };
