import React from 'react';
import { Tab, TabTypeMap, Tabs } from '@mui/material';
import { useMatch, useNavigate } from 'react-router-dom';

const tabs: TabTypeMap['props'][] = [
  {
    value: 'groups',
    label: 'Groups',
  },
  {
    value: 'group-rules',
    label: 'Group Rules',
  },
  {
    value: 'rule-values',
    label: 'Rule Values',
  },
];

const GroupTabs: React.FC = () => {
  const match = useMatch('/:page/:tab');
  const navigate = useNavigate();
  return (
    <Tabs
      value={match?.params.tab}
      indicatorColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      selectionFollowsFocus={true}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          {...tab}
          disableRipple={true}
          disableFocusRipple={true}
          onClick={() => navigate(`./${tab.value}`)}
        />
      ))}
    </Tabs>
  );
};

export default GroupTabs;
