import { readAsList } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import UserRuleValues from 'core/redux/reducers/userGroupRuleValues';
import UserGroupRules from 'core/redux/reducers/userGroupRules';
import UserGroups from 'core/redux/reducers/userGroups';
import { RuleValueFormValues } from '../partnerGroupRuleValues';

export const createUserRuleValue =
  (values: RuleValueFormValues, listId?: string): AppThunk =>
  async (dispatch) => {
    const { groupRuleId, groupId } = values;
    if (!groupRuleId || !groupId) return;

    const data = {
      attributes: {
        value: values.value,
      },
      relationships: {
        userGroup: {
          data: { id: groupId },
        },
        userGroupRule: {
          data: { id: groupRuleId },
        },
      },
    };

    try {
      await apiClient.postUserRuleValue(data);
      const apiResponse = await apiClient.getUserRuleValues({
        filter: {
          userGroupId: groupId,
          userGroupRuleId: groupRuleId,
        },
        include: ['userGroup', 'userGroupRule'],
      });

      const { entities } = readAsList(apiResponse.data);

      const { UserGroupRuleValue, UserGroup, UserGroupRule } = entities;

      if (UserGroupRuleValue) {
        dispatch(
          UserRuleValues.actions.update(UserGroupRuleValue[0], listId),
        );
      }

      if (UserGroup) {
        dispatch(UserGroups.actions.upsertMany(UserGroup));
      }
      if (UserGroupRule) {
        dispatch(UserGroupRules.actions.upsertMany(UserGroupRule));
      }
    } catch (error) {
      console.error(error);
    }
  };
