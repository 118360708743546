import { readAsList } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import UserGroups, { FlatUserGroup } from 'core/redux/reducers/userGroups';

export const createUserGroup =
  (
    attributes: Pick<
      FlatUserGroup,
      'name' | 'description' | 'id' | 'isPublic'
    >,
    listId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const apiResponse = await apiClient.postUserGroup({
        ...attributes,
        lid: attributes.id,
      });

      const { entities } = readAsList(apiResponse.data);
      const { UserGroup } = entities;

      if (UserGroup) {
        dispatch(UserGroups.actions.update(UserGroup[0], listId));
      }
    } catch (error) {
      console.error(error);
    }
  };
