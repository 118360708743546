import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Stack, useTheme } from '@mui/material';
import { CellClickedEvent } from 'ag-grid-community';
import PartnersGrid from 'components/PartnersGrid';
import PartnersGridHeader from 'components/PartnersGridHeader';
import { PartnerSidebar } from 'components/sidebar/PartnerSidebar/PartnerSidebar';
import { PartnerColumnsName } from 'components/PartnersGrid';
import { UrlParamsName } from 'constants/routes';

const PartnersPage: React.FC = () => {
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchText, setSearchText] = useState('');

  const urlSelected = searchParams.get(UrlParamsName.SELECTED);

  const [selected, setSelected] = useState<
    CellClickedEvent['data'] | null
  >(null);

  const clickCellHandler = useCallback(
    (e: CellClickedEvent) => {
      if (e.colDef.headerName === PartnerColumnsName.actions) {
        return;
      }
      if (e.data?.id) {
        setSearchParams((prevParams) => {
          prevParams.set(UrlParamsName.SELECTED, e.data?.id);
          return [...prevParams];
        });
      }
      setSelected(e.data);
    },
    // setSearchParams is not a stable function, do not include it in deps array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelected],
  );

  const onSidebarClose = useCallback(() => {
    setSearchParams((prevParams) => {
      prevParams.delete(UrlParamsName.SELECTED);
      return [...prevParams];
    });
    setSelected(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    },
    [setSearchText],
  );

  return (
    <Box
      sx={{
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflowY: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          columnGap: theme.spacing(1),
          position: 'relative',
        }}
      >
        <Stack
          sx={{
            flex: 1,
            border: `1px solid ${theme.palette.black.black20}`,
            backgroundColor: '#fff',
            borderRadius: '4px',
            '& .ag-root-wrapper': {
              border: 0,
            },
          }}
        >
          <PartnersGridHeader onSearchChange={onSearchChange} />
          <Box flex="1">
            <PartnersGrid
              onCellClicked={clickCellHandler}
              searchText={searchText}
            />
          </Box>
        </Stack>
        <PartnerSidebar
          partnerId={selected?.id || urlSelected || null}
          onClose={onSidebarClose}
        />
      </Box>
    </Box>
  );
};

export default PartnersPage;
