import { createEntityReducer } from '../createEntityReducer';
import { IdentifiableChunk } from '../types';
import { Flat, PartnerJsonApiEntity } from '@one-vision/json-api-parser';

export type FlatPartner = Flat<PartnerJsonApiEntity>;

const Partners = createEntityReducer<FlatPartner, 'id', 'partner'>({
  entityName: 'partner',
  entityIdProperty: 'id',
  idSelector: (partner) => partner.id,
});

export type PartnerChunk = IdentifiableChunk<FlatPartner, 'id'>;

export default Partners;
