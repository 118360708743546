import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import { PartnerGroup } from 'types';
import PartnerGroups, {
  FlatPartnerGroup,
} from 'core/redux/reducers/partnerGroups';

export const updatePartnerGroup =
  (
    updates: Pick<FlatPartnerGroup, 'id'> & Partial<FlatPartnerGroup>,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const result = await apiClient.updatePartnerGroup(updates);
      const {
        data: { data },
      } = result;
      const partnerGroup = data.reduce<PartnerGroup[]>((acc, group) => {
        return [
          ...acc,
          {
            id: String(group.id),
            ...group.attributes,
          },
        ];
      }, []);

      if (partnerGroup) {
        dispatch(
          PartnerGroups.actions.batch(
            partnerGroup.map((group) =>
              PartnerGroups.actions.update(group),
            ),
          ),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
