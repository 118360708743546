import { PartnerGroupRuleRId } from '@one-vision/json-api-parser';
import { useAppSelector } from 'core/redux';
import GroupRules, {
  FlatPartnerGroupRule,
} from 'core/redux/reducers/partnerGroupRules';

export function useRelatedRulesSelector({
  partnerGroupRule,
}: {
  partnerGroupRule: PartnerGroupRuleRId[];
}) {
  const ruleIds = partnerGroupRule?.length
    ? partnerGroupRule.map((rule) => rule.id as string)
    : [];

  const rules = useAppSelector(
    (state) =>
      GroupRules.selectors.selectByIds(
        state,
        ruleIds,
      ) as FlatPartnerGroupRule[],
  );

  return rules;
}
