import {
  PartnerGroupRuleValueJsonApiEntity,
  Flat,
} from '@one-vision/json-api-parser';
import { createEntityReducer } from '../createEntityReducer';
import { IdentifiableChunk } from '../types';
export type FlatPartnerGroupRuleValue =
  Flat<PartnerGroupRuleValueJsonApiEntity>;
const PartnerGroupRuleValues = createEntityReducer<
  FlatPartnerGroupRuleValue,
  'id',
  'partnerGroupRuleValue'
>({
  entityName: 'partnerGroupRuleValue',
  entityIdProperty: 'id',
  idSelector: (partnerRuleValue) => partnerRuleValue.id,
});

export type PartnerGroupRuleValueChunk = IdentifiableChunk<
  FlatPartnerGroupRuleValue,
  'id'
>;

export default PartnerGroupRuleValues;
