import { PartnerGroupRId } from '@one-vision/json-api-parser';
import { useAppSelector } from 'core/redux';
import PartnerGroups, {
  FlatPartnerGroup,
} from 'core/redux/reducers/partnerGroups';

export function useRelatedGroupsSelector({
  /**
   * Plugging in an empty array because of the BE bug.
   * Ticket https://linear.app/onevision-resources/issue/1V-868/jsonapi-relationship-object-should-have-a-relation-field
   */
  partnerGroup = [],
}: {
  partnerGroup: PartnerGroupRId[];
}) {
  const groupIds = partnerGroup?.length
    ? partnerGroup.map((group) => group.id as string)
    : [];

  const groups = useAppSelector(
    (state) =>
      PartnerGroups.selectors.selectByIds(
        state,
        groupIds,
      ) as FlatPartnerGroup[],
  );

  return groups;
}
