const SUBDOMAIN_PART = 'dev-api.';
type ReactAppStage = 'prod' | 'uat' | 'dev' | 'local';

let api = process.env.REACT_APP_API_URL || '';
if (process.env.REACT_APP_API_URL_SUFFIX && api.includes(SUBDOMAIN_PART)) {
  // we need this part for custom environments (ada|eve|mia|zoe etc)
  api = api
    .split(SUBDOMAIN_PART)
    .join(
      SUBDOMAIN_PART.replace(
        '-',
        `${process.env.REACT_APP_API_URL_SUFFIX}-`,
      ),
    );
}

export const config = {
  stage: process.env.REACT_APP_STAGE as ReactAppStage,
  api,
  reportingApi: process.env.REACT_APP_ERRORS_API_URL as string,
  apiKeys: {
    reportingApiKey: '', // will be set after auth
    partnerApiKey: '', // will be set after auth
  },
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
  cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN as string,
  cognitoUserPoolRegion: process.env
    .REACT_APP_COGNITO_USER_POOL_REGION as string,
  cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
};
