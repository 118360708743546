import { JsonApiId } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import { FlatPartnerGroup } from 'core/redux/reducers/partnerGroups';
import { fetchPartners } from './fetchPartners';

export const updateAssociatedGroups =
  (
    partnerId: JsonApiId,
    groups: FlatPartnerGroup[],
    listId?: string,
  ): AppThunk =>
  async (dispatch) => {
    const payload = {
      data: groups,
    };

    try {
      await apiClient.patchAssociatedGroups(partnerId, payload);
    } catch (error) {
      console.error(error);
    }

    dispatch(
      fetchPartners(
        {
          filter: {
            ovpid: partnerId,
          },
        },
        listId,
      ),
    );
  };
