import { readAsList } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import GroupRules from 'core/redux/reducers/partnerGroupRules';
import PartnerGroups from 'core/redux/reducers/partnerGroups';
import RuleValues from 'core/redux/reducers/partnerGroupRuleValues';

export const fetchPartnerRuleValue =
  (id: string): AppThunk =>
  async (dispatch) => {
    const apiResponse = await apiClient.getRuleValue(id, {
      include: ['partnerGroup', 'partnerGroupRule'],
    });
    const { entities } = readAsList(apiResponse.data);

    const { PartnerGroupRuleValue, PartnerGroup, PartnerGroupRule } =
      entities;

    if (PartnerGroupRuleValue) {
      dispatch(RuleValues.actions.upsertMany(PartnerGroupRuleValue));
    }

    if (PartnerGroup) {
      dispatch(PartnerGroups.actions.upsertMany(PartnerGroup));
    }
    if (PartnerGroupRule) {
      dispatch(GroupRules.actions.upsertMany(PartnerGroupRule));
    }
  };
