import {
  AnyAction,
  Middleware,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import PartnerGroups from './reducers/partnerGroups';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import PartnerGroupRules from './reducers/partnerGroupRules';
import PartnerGroupRuleValues from './reducers/partnerGroupRuleValues';
import UserGroupRules from './reducers/userGroupRules';
import UserGroupRuleValues from './reducers/userGroupRuleValues';
import Partners from './reducers/partners';
import { snackbar } from './reducers/snackbar';
import UserGroups from './reducers/userGroups';

export const rootReducer = combineReducers({
  partnerGroupEntities: PartnerGroups.reducer,
  partnerGroupRuleEntities: PartnerGroupRules.reducer,
  partnerGroupRuleValueEntities: PartnerGroupRuleValues.reducer,
  partnerEntities: Partners.reducer,
  userGroupEntities: UserGroups.reducer,
  userGroupRuleEntities: UserGroupRules.reducer,
  userGroupRuleValuesEntities: UserGroupRuleValues.reducer,
  ...snackbar.reducer,
});

const middlewares: Middleware[] = [];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(...middlewares),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, AppState, unknown, AnyAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export const actions = { ...snackbar.actions };
