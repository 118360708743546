import React, { useEffect, useState } from 'react';
import { Store } from '@mui/icons-material';
import { Box, DialogTitle, Typography, useTheme } from '@mui/material';
import { formatDateToLocalString } from 'utils/date.utils';
import { PAGE_HEADER_HEIGHT } from 'constants/style';
import { PartnerEntity } from './PartnerSidebar';

const OPTIONS_LOCALE = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
} as const;

export const PartnerSidebarHeader: React.FC<{
  partnerData: PartnerEntity;
}> = ({ partnerData }) => {
  const theme = useTheme();

  const [time, setTime] = useState<string>('');

  useEffect(() => {
    if (!partnerData) {
      return;
    }

    const interval = setInterval(() => {
      const newDate = formatDateToLocalString({
        options: {
          ...OPTIONS_LOCALE,
          timeZone: partnerData.timeZone as string,
        },
      });
      setTime(newDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [partnerData]);

  return (
    <DialogTitle
      sx={{
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `3px solid ${theme.palette.black.black20}`,
        height: PAGE_HEADER_HEIGHT,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Store
          sx={{
            height: '1.25em',
            width: '1.78em',
            fill: theme.palette.black.black35,
            marginRight: '0.5em',
          }}
        />
        <Typography
          sx={{
            fontSize: '0.8em',
            fontWeight: 500,
          }}
        >
          {partnerData.businessName}
        </Typography>
      </Box>
      <Box sx={{ maxWidth: '50%' }}>
        <Typography
          sx={{
            color: theme.palette.black.black65,
            fontSize: '0.6em',
            textAlign: 'end',
            wordBreak: 'break-word',
          }}
        >
          {time}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.black.black65,
            fontSize: '0.6em',
            textAlign: 'end',
            wordBreak: 'break-word',
          }}
        >
          {partnerData.timeZone}
        </Typography>
      </Box>
    </DialogTitle>
  );
};
