import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import UserGroupRules, {
  FlatUserGroupRule,
} from 'core/redux/reducers/userGroupRules';
import { UserGroupRule } from 'types';

export const updateUserGroupRule =
  (
    updates: Pick<FlatUserGroupRule, 'id'> & Partial<FlatUserGroupRule>,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const result = await apiClient.updateUserGroupRule(updates);
      const {
        data: { data },
      } = result;
      const groupRules = data.reduce<UserGroupRule[]>((acc, group) => {
        return [
          ...acc,
          {
            id: group.id as string,
            ...group.attributes,
          },
        ];
      }, []);
      dispatch(
        UserGroupRules.actions.batch(
          groupRules.map((groupRule) =>
            UserGroupRules.actions.update(groupRule),
          ),
        ),
      );
    } catch (error) {
      console.error(error);
    }
  };
