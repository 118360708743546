import { UserGroupRuleRId } from '@one-vision/json-api-parser';
import { useAppSelector } from 'core/redux';
import GroupRules, {
  FlatUserGroupRule,
} from 'core/redux/reducers/userGroupRules';

export function useRelatedUserRulesSelector({
  userGroupRule,
}: {
  userGroupRule: UserGroupRuleRId[];
}) {
  const ruleIds = userGroupRule?.length
    ? userGroupRule.map((rule) => rule.id as string)
    : [];

  const rules = useAppSelector(
    (state) =>
      GroupRules.selectors.selectByIds(
        state,
        ruleIds,
      ) as FlatUserGroupRule[],
  );

  return rules;
}
