import { readAsList } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import { getNextPage } from 'core/redux/reducers/helpers';
import PartnerGroups from 'core/redux/reducers/partnerGroups';
import { ListReducerState } from 'core/redux/reducers/types';

export const fetchPartnerGroups =
  (options: Partial<ListReducerState> = {}, listId?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const listState = PartnerGroups.selectors.selectList(
      state,
      listId || '',
    );
    const nextPage = getNextPage(options.page, listState);

    const limit = options.perPage || listState.perPage;
    const offset = limit * (nextPage - 1);

    delete options.perPage;
    const apiResponse = await apiClient.getPartnerGroups({
      include: listState.include,
      search: listState.search,
      ...options,
      page: {
        limit,
        offset,
      },
    });
    const {
      data: { meta: responseMeta },
    } = apiResponse;

    const { entities } = readAsList(apiResponse.data);

    const { PartnerGroup } = entities;

    if (PartnerGroup && responseMeta) {
      const { page, defaultPage, ...meta } = responseMeta;
      dispatch(
        PartnerGroups.actions.batch([
          PartnerGroups.actions.updateList(
            {
              ...meta,
              ids: PartnerGroup.map((group) => group.id),
              page: page ? page.offset / page.limit + 1 : nextPage,
              perPage: page ? +page.limit : listState.perPage,
              lastPage: Math.ceil(
                meta.totalCount / (page ? page.limit : listState.perPage),
              ),
            },
            listId,
          ),
          PartnerGroups.actions.upsertMany(PartnerGroup),
        ]),
      );
    }
  };
