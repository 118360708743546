import React, { useEffect, useMemo, useState } from 'react';
import { PartnerJsonApiEntity } from '@one-vision/json-api-parser';
import { Sidebar } from '../../shared/Sidebar';
import { apiClient } from 'core/api';
import { PartnerSidebarHeader } from './PartnerSidebarHeader';
import { PartnerSidebarContent } from './PartnerSidebarContent';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { actions } from 'core/redux';

interface Props {
  partnerId: number | null;
  onClose: () => void;
}

export type PartnerEntity = PartnerJsonApiEntity['attributes'] & {
  id: number;
};

export const PartnerSidebar: React.FC<Props> = ({
  partnerId,
  onClose,
}) => {
  const [partnerData, setPartnerData] = useState<PartnerEntity | null>(
    null,
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!partnerId) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const {
          data: {
            data: [partner],
          },
        } = await apiClient.getPartners({
          filter: {
            ovpid: partnerId,
          },
        });
        if (!partner?.id) {
          onClose();
          return;
        }
        setPartnerData({
          ...partner.attributes,
          id: partner.id as number,
        });
      } catch {
        dispatch(
          actions.updateSnackbar({
            type: 'error',
            text: 'Can`t fetch partner, please try again',
          }),
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [partnerId, onClose, dispatch]);

  const partnerDataHandler = (data: PartnerEntity) => {
    setPartnerData(data);
  };

  const sidebarContent = useMemo(
    () =>
      loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {partnerData && (
            <Stack sx={{ height: '100%' }}>
              <PartnerSidebarHeader partnerData={partnerData} />
              <PartnerSidebarContent
                partnerData={partnerData}
                onDataChange={partnerDataHandler}
              />
            </Stack>
          )}
        </>
      ),
    [partnerData, loading],
  );

  return (
    <Sidebar
      open={!!partnerId}
      onClose={onClose}
      content={sidebarContent}
    />
  );
};
