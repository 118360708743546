import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import GroupRules, {
  FlatPartnerGroupRule,
} from 'core/redux/reducers/partnerGroupRules';
import { PartnerGroupRule } from 'types';

export const updatePartnerGroupRule =
  (
    updates: Pick<FlatPartnerGroupRule, 'id'> &
      Partial<FlatPartnerGroupRule>,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const result = await apiClient.updatePartnerGroupRule(updates);
      const {
        data: { data },
      } = result;
      const groupRules = data.reduce<PartnerGroupRule[]>((acc, group) => {
        return [
          ...acc,
          {
            id: group.id,
            ...group.attributes,
          },
        ];
      }, []);
      dispatch(
        GroupRules.actions.batch(
          groupRules.map((groupRule) =>
            GroupRules.actions.update(groupRule),
          ),
        ),
      );
    } catch (error) {
      console.error(error);
    }
  };
