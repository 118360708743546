import { useContext } from 'react';
import ModalContext from './modal-context';

const useModalManager = () => {
  const { openModal, closeModal, clearModal, propsDictionary } =
    useContext(ModalContext);

  return { openModal, closeModal, clearModal, propsDictionary };
};

export default useModalManager;
