import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Zoom,
  useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
  ModalManagerProps,
  withModalManager,
} from 'components/modal-manager';
import React, { useCallback } from 'react';
import DeleteForever from '@mui/icons-material/DeleteForever';

const ConfirmDeletionModal: React.FC<ModalManagerProps<boolean>> = ({
  isOpen,
  close,
  clear,
}) => {
  const theme = useTheme();

  const cancelHandler = useCallback(() => {
    close(false);
  }, [close]);
  const confirmHandler = useCallback(() => {
    close(true);
  }, [close]);
  return (
    <Dialog
      open={isOpen}
      onClose={cancelHandler}
      closeAfterTransition
      transitionDuration={400}
      TransitionComponent={Zoom}
      TransitionProps={
        {
          onExited: clear,
          mountOnEnter: true,
          unmountOnExit: true,
        } as TransitionProps
      }
      disableRestoreFocus={true}
    >
      <DialogTitle variant="h4">
        <Box display="flex" flexDirection="column" alignItems="center">
          <DeleteForever
            sx={{
              fontSize: '5.2rem',
              color: theme.palette.error.main,
            }}
          />
          Are you sure?
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center">
          Do you really want to delete this record?
          <br />
          This process cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: theme.spacing(2) }}>
        <Button
          onClick={cancelHandler}
          sx={{
            color: theme.palette.black.black65,
            fontSize: '1.4rem',
          }}
          autoFocus
        >
          Cancel
        </Button>
        <Button
          onClick={confirmHandler}
          variant="contained"
          color="error"
          sx={{
            color: theme.palette.black.black5,
            fontSize: '1.4rem',
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withModalManager()(ConfirmDeletionModal);
