import axios from 'axios';
import { config } from './config';
import { MapLike } from 'typescript';
import { auth } from '@one-vision/login';
import {
  JsonApiMeta,
  JsonApiParams,
  PartnerGroupRuleJsonApi,
} from 'types';
import { buildQuery } from 'utils/buildQuery';
import {
  JsonApiId,
  JsonApiMessage,
  PartnerGroupJsonApiEntity,
  PartnerGroupRId,
  PartnerGroupRuleJsonApiEntity,
  PartnerGroupRuleRId,
  PartnerGroupRuleValueJsonApiEntity,
  PartnerJsonApiEntity,
  UserGroupJsonApiEntity,
  UserGroupRId,
  UserGroupRuleJsonApiEntity,
  UserGroupRuleRId,
  UserGroupRuleValueJsonApiEntity,
} from '@one-vision/json-api-parser';
import { FlatPartnerGroup } from './redux/reducers/partnerGroups';
import { FlatPartnerGroupRule } from './redux/reducers/partnerGroupRules';
import { FlatPartner } from './redux/reducers/partners';
import { FlatUserGroup } from './redux/reducers/userGroups';
import { FlatUserGroupRule } from './redux/reducers/userGroupRules';

const SUB_ROUTE_V2 = `v2`;

export class ApiClient {
  private baseUrl = config.api;
  private local = config.stage === 'local' ? '/admin' : '';

  private baseGet = async <T>(
    path: string,
    params?: MapLike<string | number | undefined>,
  ) => {
    const fullPath = `${this.baseUrl}/${SUB_ROUTE_V2}${this.local}/${path}`;
    try {
      return await axios.get<T>(fullPath, {
        headers: await this.formHeaders(),
        params,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  private basePost = async <T>(
    path: string,
    payload: unknown,
    params?: MapLike<string>,
  ) => {
    const fullPath = `${this.baseUrl}/${SUB_ROUTE_V2}${this.local}/${path}`;
    try {
      return await axios.post<T>(fullPath, payload, {
        headers: await this.formHeaders(),
        params,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  private basePatch = async <T>(
    path: string,
    payload: unknown,
    params?: MapLike<string>,
  ) => {
    const fullPath = `${this.baseUrl}/${SUB_ROUTE_V2}${this.local}/${path}`;
    try {
      return await axios.patch<T>(fullPath, payload, {
        headers: await this.formHeaders(),
        params,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  private baseDelete = async <T>(
    path: string,
    params?: MapLike<string>,
  ) => {
    const fullPath = `${this.baseUrl}/${SUB_ROUTE_V2}${this.local}/${path}`;
    try {
      return await axios.delete<T>(fullPath, {
        headers: await this.formHeaders(),
        params,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  private formHeaders = async () => {
    const token = await auth.getToken();
    const headers: MapLike<string | string> = {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    if (auth.getIsAdmin()) {
      headers['partner-id'] = String(auth.getPartnerId());
    }

    return headers;
  };

  public axios = axios;

  /** PARTNER */

  createPartner = ({
    id,
    ...attributes
  }: Pick<FlatPartner, 'id'> & Partial<FlatPartner>) => {
    return this.basePost<{
      data: PartnerJsonApiEntity;
    }>('partner', {
      data: {
        id,
        type: 'Partner',
        attributes,
      },
    });
  };

  patchPartner = ({
    id,
    ...attributes
  }: Pick<FlatPartner, 'id'> & Partial<FlatPartner>) => {
    return this.basePatch<{
      data: PartnerJsonApiEntity[];
    }>('partner', {
      data: {
        id,
        type: 'Partner',
        attributes,
      },
    });
  };

  getPartners = (options: JsonApiParams) => {
    return this.baseGet<{
      data: PartnerJsonApiEntity[];
      included: PartnerGroupJsonApiEntity[];
      meta: JsonApiMeta;
    }>(`partner${buildQuery({ ...options })}`);
  };

  patchAssociatedGroups = (
    partnerId: JsonApiId,
    payload: { data: Pick<FlatPartnerGroup, 'id'>[] },
  ) => {
    return this.basePatch<{ data: null }>(
      `partner-to-partner-group${buildQuery({ partnerId })}`,
      payload,
    );
  };

  /** PARTNER GROUP */

  postPartnerGroup = async (
    payload: Pick<FlatPartnerGroup, 'name' | 'description'> & {
      lid?: JsonApiId;
    },
  ) => {
    const response = await this.basePost<{
      data: PartnerGroupJsonApiEntity;
    }>('partner-group', {
      data: {
        attributes: payload,
      },
    });

    return {
      ...response,
      data: {
        ...response.data,
        data: [response.data.data],
        included: [],
        meta: {},
      },
    };
  };

  getPartnerGroups = (options: JsonApiParams) => {
    return this.baseGet<
      JsonApiMessage<PartnerGroupJsonApiEntity, JsonApiMeta>
    >(`partner-group${buildQuery({ ...options })}`);
  };

  getPartnerGroup = (options?: JsonApiParams) => {
    return this.baseGet<JsonApiMessage<PartnerGroupJsonApiEntity>>(
      `partner-group${buildQuery({
        ...(options || {}),
      })}`,
    );
  };

  updatePartnerGroup = (
    updates: Pick<FlatPartnerGroup, 'id'> & Partial<FlatPartnerGroup>,
  ) => {
    return this.basePatch<JsonApiMessage<PartnerGroupJsonApiEntity>>(
      'partner-group',
      {
        data: {
          id: updates.id,
          attributes: {
            name: updates.name,
            description: updates.description,
          },
        },
      },
    );
  };

  deletePartnerGroup = (options: { id: string }) => {
    return this.baseDelete(
      `partner-group${buildQuery({ partnerGroupId: options.id })}`,
    );
  };

  /** PARTNER GROUP RULES */

  postPartnerGroupRule = async (
    payload: Pick<FlatPartnerGroupRule, 'name' | 'description'> & {
      lid?: JsonApiId;
    },
  ) => {
    const response = await this.basePost<{
      data: PartnerGroupRuleJsonApiEntity;
    }>('partner-group-rule', {
      data: {
        attributes: payload,
      },
    });

    return {
      ...response,
      data: {
        ...response.data,
        data: [response.data.data],
        included: [],
        meta: {},
      },
    };
  };

  getPartnerGroupRules = (options: JsonApiParams) => {
    return this.baseGet<{
      data: PartnerGroupRuleJsonApiEntity[];
      included: [];
      meta: JsonApiMeta;
    }>(`partner-group-rule${buildQuery({ ...options })}`);
  };

  getPartnerGroupRule = (options?: JsonApiParams) => {
    return this.baseGet<JsonApiMessage<PartnerGroupRuleJsonApiEntity>>(
      `partner-group-rule${buildQuery({
        ...(options || {}),
      })}`,
    );
  };

  updatePartnerGroupRule = (
    updates: Pick<FlatPartnerGroupRule, 'id'> &
      Partial<FlatPartnerGroupRule>,
  ) => {
    return this.basePatch<{ data: PartnerGroupRuleJsonApi[] }>(
      'partner-group-rule',
      {
        data: {
          id: updates.id,
          attributes: {
            name: updates.name,
            description: updates.description,
          },
        },
      },
    );
  };

  deletePartnerGroupRule = (options: { id: string }) => {
    return this.baseDelete(
      `partner-group-rule${buildQuery({
        partnerGroupRuleId: options.id,
      })}`,
    );
  };

  /** PARTNER GROUP RULE VALUES */

  postRuleValue = (data: {
    attributes: Pick<
      PartnerGroupRuleValueJsonApiEntity['attributes'],
      'value'
    >;
    relationships: {
      partnerGroup: {
        data: Pick<PartnerGroupRId, 'id'>;
      };
      partnerGroupRule: {
        data: Pick<PartnerGroupRuleRId, 'id'>;
      };
    };
  }) => {
    return this.basePost<
      JsonApiMessage<PartnerGroupRuleValueJsonApiEntity>
    >('partner-group-rule-value', { data });
  };

  getRuleValues = (options: JsonApiParams) => {
    return this.baseGet<
      JsonApiMessage<PartnerGroupRuleValueJsonApiEntity>
    >(`partner-group-rule-value${buildQuery({ ...options })}`);
  };

  getRuleValue = (id: string, options?: JsonApiParams) => {
    return this.baseGet<
      JsonApiMessage<PartnerGroupRuleValueJsonApiEntity>
    >(
      `partner-group-rule-value${buildQuery({
        partnerGroupRuleValue: id,
        ...(options || {}),
      })}`,
    );
  };

  patchRuleValue = (data: {
    attributes: Pick<
      PartnerGroupRuleValueJsonApiEntity['attributes'],
      'value'
    >;
    relationships: {
      partnerGroup: {
        data: { id: JsonApiId };
      };
      partnerGroupRule: {
        data: { id: JsonApiId };
      };
    };
  }) => {
    return this.basePatch<
      JsonApiMessage<PartnerGroupRuleValueJsonApiEntity>
    >('partner-group-rule-value?include=partnerGroup,partnerGroupRule', {
      data,
    });
  };

  deleteRuleValue = (options: { id: JsonApiId }) => {
    return this.baseDelete(
      `partner-group-rule-value${buildQuery({
        partnerGroupRuleValueId: options.id,
      })}`,
    );
  };

  /** USER GROUP */

  postUserGroup = async (
    payload: Pick<FlatUserGroup, 'name' | 'description' | 'isPublic'> & {
      lid?: JsonApiId;
    },
  ) => {
    const response = await this.basePost<{
      data: UserGroupJsonApiEntity;
    }>('user-group', {
      data: {
        attributes: payload,
      },
    });

    return {
      ...response,
      data: {
        ...response.data,
        data: [response.data.data],
        included: [],
        meta: {},
      },
    };
  };

  getUserGroups = (options: JsonApiParams) => {
    return this.baseGet<
      JsonApiMessage<UserGroupJsonApiEntity, JsonApiMeta>
    >(`user-group${buildQuery({ ...options })}`);
  };

  getUserGroup = (options?: JsonApiParams) => {
    return this.baseGet<JsonApiMessage<UserGroupJsonApiEntity>>(
      `user-group${buildQuery({
        ...(options || {}),
      })}`,
    );
  };

  updateUserGroup = (
    updates: Pick<FlatUserGroup, 'id'> & Partial<FlatUserGroup>,
  ) => {
    return this.basePatch<JsonApiMessage<UserGroupJsonApiEntity>>(
      'user-group',
      {
        data: {
          id: updates.id,
          attributes: {
            name: updates.name,
            description: updates.description,
            isPublic: updates.isPublic,
          },
        },
      },
    );
  };

  deleteUserGroup = (options: { id: string }) => {
    return this.baseDelete(
      `user-group${buildQuery({ userGroupId: options.id })}`,
    );
  };

  /** USER GROUP RULES */

  postUserGroupRule = async (
    payload: Pick<FlatUserGroupRule, 'name' | 'description'> & {
      lid?: JsonApiId;
    },
  ) => {
    const response = await this.basePost<{
      data: UserGroupRuleJsonApiEntity;
    }>('user-group-rule', {
      data: {
        attributes: payload,
      },
    });

    return {
      ...response,
      data: {
        ...response.data,
        data: [response.data.data],
        included: [],
        meta: {},
      },
    };
  };

  getUserGroupRules = (options: JsonApiParams) => {
    return this.baseGet<{
      data: UserGroupRuleJsonApiEntity[];
      included: [];
      meta: JsonApiMeta;
    }>(`user-group-rule${buildQuery({ ...options })}`);
  };

  getUserGroupRule = (options?: JsonApiParams) => {
    return this.baseGet<JsonApiMessage<UserGroupRuleJsonApiEntity>>(
      `user-group-rule${buildQuery({
        ...(options || {}),
      })}`,
    );
  };

  updateUserGroupRule = (
    updates: Pick<FlatUserGroupRule, 'id'> & Partial<FlatUserGroupRule>,
  ) => {
    return this.basePatch<{ data: UserGroupRuleJsonApiEntity[] }>(
      'user-group-rule',
      {
        data: {
          id: updates.id,
          attributes: {
            name: updates.name,
            description: updates.description,
          },
        },
      },
    );
  };

  deleteUserGroupRule = (options: { id: string }) => {
    return this.baseDelete(
      `user-group-rule${buildQuery({
        userGroupRuleId: options.id,
      })}`,
    );
  };

  /** USER GROUP RULE VALUES */

  postUserRuleValue = (data: {
    attributes: Pick<
      UserGroupRuleValueJsonApiEntity['attributes'],
      'value'
    >;
    relationships: {
      userGroup: {
        data: Pick<UserGroupRId, 'id'>;
      };
      userGroupRule: {
        data: Pick<UserGroupRuleRId, 'id'>;
      };
    };
  }) => {
    return this.basePost<JsonApiMessage<UserGroupRuleValueJsonApiEntity>>(
      'user-group-rule-value',
      { data },
    );
  };

  getUserRuleValues = (options: JsonApiParams) => {
    return this.baseGet<JsonApiMessage<UserGroupRuleValueJsonApiEntity>>(
      `user-group-rule-value${buildQuery({ ...options })}`,
    );
  };

  getUserRuleValue = (id: string, options?: JsonApiParams) => {
    return this.baseGet<JsonApiMessage<UserGroupRuleValueJsonApiEntity>>(
      `user-group-rule-value${buildQuery({
        userGroupRuleValue: id,
        ...(options || {}),
      })}`,
    );
  };

  patchUserRuleValue = (data: {
    attributes: Pick<
      UserGroupRuleValueJsonApiEntity['attributes'],
      'value'
    >;
    relationships: {
      userGroup: {
        data: { id: JsonApiId };
      };
      userGroupRule: {
        data: { id: JsonApiId };
      };
    };
  }) => {
    return this.basePatch<JsonApiMessage<UserGroupRuleValueJsonApiEntity>>(
      'user-group-rule-value?include=userGroup,userGroupRule',
      {
        data,
      },
    );
  };

  deleteUserRuleValue = (options: { id: JsonApiId }) => {
    return this.baseDelete(
      `user-group-rule-value${buildQuery({
        userGroupRuleValueId: options.id,
      })}`,
    );
  };

  public generatePresignedUrl = ({
    contentType,
  }: {
    contentType: string;
  }) =>
    this.baseGet<{
      data: { key: string; uploadURL: string };
    }>('presigned-url', { contentType });
}

export const apiClient = new ApiClient();
