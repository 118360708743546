import { createEntityReducer } from '../createEntityReducer';
import { IdentifiableChunk } from '../types';
import {
  Flat,
  PartnerGroupJsonApiEntity,
} from '@one-vision/json-api-parser';

export type FlatPartnerGroup = Flat<PartnerGroupJsonApiEntity>;

const PartnerGroups = createEntityReducer<
  FlatPartnerGroup,
  'id',
  'partnerGroup'
>({
  entityName: 'partnerGroup',
  entityIdProperty: 'id',
  idSelector: (partnerGroup) => partnerGroup.id,
});

export type PartnerGroupChunk = IdentifiableChunk<FlatPartnerGroup, 'id'>;

export default PartnerGroups;
