import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { PatternFormat } from 'react-number-format';
import { statesList } from 'constants/states';
import { objectDifference } from 'utils/object.utils';
import { apiClient } from 'core/api';
import {
  InputType,
  ListItem,
  fieldsList,
  timeZonesList,
} from './FieldsList';
import { schema } from './validationSchema';
import { useDispatch } from 'react-redux';
import { actions } from 'core/redux';
import { PartnerEntity } from './PartnerSidebar';
import { PHONE_PATTERN } from 'constants/text';
import { DatePickerInput } from 'components/shared/DatePickerInput';

const getSwitchValue = (value: string | boolean | null) => {
  if (value === null || typeof value === 'boolean') {
    return !!value;
  }

  const val = value.toLocaleLowerCase();

  return val === 'yes' || val === 'true';
};

const getInput = (
  item: ListItem,
  value: string | boolean | Date | null,
  onChange: (value: string | boolean | Date | null) => void,
  error: string,
  key: string,
) => {
  switch (item.inputType) {
    case InputType.input:
    case InputType.email:
      return (
        <TextField
          key={key}
          label={item.label}
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          error={!!error}
          helperText={error}
        />
      );
    case InputType.autocomplete:
      return (
        <Autocomplete
          id={item.name}
          key={key}
          renderInput={(params) => (
            <TextField
              {...params}
              label={item.label}
              error={!!error}
              helperText={error ? error : undefined}
              fullWidth
            />
          )}
          options={
            item.options as {
              value: string | true;
              text: string | undefined;
            }[]
          }
          getOptionLabel={(option) => option?.text || ''}
          isOptionEqualToValue={(option) => option.value === value}
          value={{
            value: value || '',
            text: [...timeZonesList, ...statesList].find(
              (el) => el.value === value,
            )?.text,
          }}
          onChange={(_, newValue) => onChange(newValue?.value || null)}
        />
      );
    case InputType.datePicker:
      return (
        <DatePickerInput
          key={key}
          name={item.name}
          error={error}
          label={item.label}
          value={value as string}
          setFieldValue={onChange}
        />
      );

    case InputType.toggle:
      return (
        <FormGroup key={key} row>
          <FormControlLabel
            control={
              <Switch
                checked={getSwitchValue(value as boolean)}
                onChange={(_, checked) => onChange(checked)}
              />
            }
            label={item.label}
            labelPlacement="start"
          />
        </FormGroup>
      );
    case InputType.phone:
      return (
        <PatternFormat
          key={key}
          mask="_"
          customInput={TextField}
          placeholder={'+1 (212) 000-0000'}
          format={PHONE_PATTERN}
          error={!!error}
          helperText={error}
          value={typeof value === 'boolean' ? '' : (value as string)}
          label={item.label}
          fullWidth
          onChange={(e) => onChange(e.target.value)}
        />
      );
    default:
      return <Box>No such type</Box>;
  }
};

export const PartnerSidebarContent: React.FC<{
  partnerData: PartnerEntity;
  onDataChange: (data: PartnerEntity) => void;
}> = ({ partnerData, onDataChange }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: partnerData,
    validationSchema: schema,
    onSubmit: async (values) => {
      setLoading(true);

      const changes = objectDifference(partnerData, values);
      const numberOfFields = Object.keys(changes).length;

      if (Object.keys(changes).includes('currentPartner')) {
        changes.currentPartner = changes.currentPartner ? 'yes' : 'no';
      }

      try {
        const {
          data: {
            data: [updated],
          },
        } = await apiClient.patchPartner({
          id: partnerData.id,
          ...changes,
        });
        onDataChange({ ...partnerData, ...updated.attributes });
        dispatch(
          actions.updateSnackbar({
            text: `Field${numberOfFields > 1 ? 's' : ''} updated`,
            type: 'success',
          }),
        );
      } catch (e) {
        const err = e as {
          response: {
            data: { errors: { title: string; detail: string }[] };
          };
        };
        const error = err?.response?.data?.errors[0];

        dispatch(
          actions.updateSnackbar({
            text: error
              ? `${error.title.split('.').join(' ')} ${error.detail}`
              : 'Something went wrong',
            type: 'error',
          }),
        );
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues(partnerData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerData]);

  return (
    <Box
      sx={{
        overflowY: 'auto',
        flex: 1,
        width: '100%',
      }}
    >
      <Box
        sx={{
          padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
        }}
      >
        <Typography
          sx={{
            color: theme.palette.black.black65,
            fontSize: theme.spacing(2),
            fontWeight: 500,
            marginBottom: theme.spacing(2),
          }}
        >
          Partner
        </Typography>
        <Stack spacing={1.5}>
          {fieldsList.map((el) =>
            getInput(
              el,
              formik.values[el.name],
              (value: string | boolean | Date | null) => {
                formik.setFieldValue(el.name, value);
              },
              formik.errors[el.name] || '',
              el.name + partnerData.id,
            ),
          )}
        </Stack>
      </Box>
      <Box
        sx={{
          display: formik.dirty ? 'flex' : 'none',
          position: 'sticky',
          bottom: 0,
          width: '100%',
          justifyContent: 'end',
          backgroundColor: theme.palette.black.black5,
          padding: theme.spacing(1.5),
          zIndex: 1,
        }}
      >
        <Button
          disabled={!formik.dirty || loading}
          sx={{ padding: theme.spacing(1.5) }}
          variant="contained"
          onClick={() => formik.handleSubmit()}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
