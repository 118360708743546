import {
  UserGroupRuleValueJsonApiEntity,
  Flat,
} from '@one-vision/json-api-parser';
import { createEntityReducer } from '../createEntityReducer';
import { IdentifiableChunk } from '../types';

export type FlatUserGroupRuleValue = Flat<UserGroupRuleValueJsonApiEntity>;

const UserGroupRuleValues = createEntityReducer<
  FlatUserGroupRuleValue,
  'id',
  'userGroupRuleValues'
>({
  entityName: 'userGroupRuleValues',
  entityIdProperty: 'id',
  idSelector: (userGroupRuleValue) => userGroupRuleValue.id,
});

export type UserGroupRuleValueChunk = IdentifiableChunk<
  FlatUserGroupRuleValue,
  'id'
>;

export default UserGroupRuleValues;
