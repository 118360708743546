import React, { useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useModalManager } from './modal-manager';
import { ModalIDs } from 'constants/modals';
import { Button } from '@mui/material';
import { CreateValueDialogProps } from './modals/CreateValueDialog';

const NewValueButton: React.FC<CreateValueDialogProps> = ({
  schema,
  createThunk,
  groupAutocomplete,
  groupRuleAutocomplete,
}) => {
  const { openModal } = useModalManager();
  const handleClick = useCallback(() => {
    openModal<CreateValueDialogProps, void>(ModalIDs.CreateValueDialog, {
      schema,
      createThunk,
      groupAutocomplete,
      groupRuleAutocomplete,
    });
  }, [
    openModal,
    schema,
    createThunk,
    groupAutocomplete,
    groupRuleAutocomplete,
  ]);

  return (
    <Button
      startIcon={<AddIcon />}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      New Rule Value
    </Button>
  );
};

export default NewValueButton;
