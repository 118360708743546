import { CalendarPickerView } from '@mui/x-date-pickers';

export const DEFAULT_INPUT_DATE_FORMAT = 'yyyy-MM-dd';

export const DEFAULT_INPUT_DATE_MASK = '____-__-__';

export const DEFAULT_PICKER_VIEWS: CalendarPickerView[] = [
  'year',
  'month',
  'day',
];
