import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  useTheme,
  InputAdornment,
  CircularProgress,
  FormHelperText,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { DEFAULT_LIST_ID } from 'constants/redux';

import {
  ModalManagerProps,
  withModalManager,
} from 'components/modal-manager';
import { CUSTOM_CLASSES } from 'core/theme';
import { AppThunk, useAppDispatch } from 'core/redux';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as yup from 'yup';
import { uploadFile } from 'core/s3';
import { RichTextEditor } from 'components/rich-text-editor';
import { replaceNewLineWithParagraph } from 'utils/text';
import { FlatPartnerGroupRule } from 'core/redux/reducers/partnerGroupRules';
import { FlatUserGroupRule } from 'core/redux/reducers/userGroupRules';

const requiredStringSchema = yup.string().required('Field is required');

type PartnerRuleThunk = (
  attributes: Pick<FlatPartnerGroupRule, 'name' | 'description' | 'id'>,
  listId?: string,
) => AppThunk;

type UserRuleThunk = (
  attributes: Pick<FlatUserGroupRule, 'name' | 'description' | 'id'>,
  listId?: string,
) => AppThunk;

export interface CreateGroupRuleDialogProps {
  createThunk: PartnerRuleThunk | UserRuleThunk;
  idSchema: yup.StringSchema<
    string | undefined,
    yup.AnyObject,
    undefined,
    ''
  >;
}

interface FromValues {
  id: string;
  name: string;
  description: string;
}

export const CreateRuleDialog: React.FC<
  CreateGroupRuleDialogProps & ModalManagerProps
> = ({ isOpen, close, clear, createThunk, idSchema }) => {
  const theme = useTheme();
  const [isSubmitting, setSubmitionState] = useState(false);
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => close(), [close]);

  const validateRequiredString = useCallback((value: string) => {
    try {
      requiredStringSchema.validateSync(value);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return error.message;
      }
    }
  }, []);

  const validateId = useCallback(
    async (value: string) => {
      try {
        requiredStringSchema.validateSync(value);
        await idSchema.validate(value);
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          return error.message;
        }
      }
    },
    [idSchema],
  );

  const handleSubmit = useCallback(
    async (values: FromValues) => {
      setSubmitionState(true);
      await dispatch(
        createThunk(
          {
            ...values,
          },
          DEFAULT_LIST_ID,
        ),
      );
      close();
    },
    [setSubmitionState, dispatch, close, createThunk],
  );

  const s3Client = useMemo(() => {
    return {
      uploadFile,
    };
  }, []);

  return (
    <Dialog
      className={CUSTOM_CLASSES.DRAWER}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      transitionDuration={400}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'left',
          onExited: clear,
          mountOnEnter: true,
          unmountOnExit: true,
        } as TransitionProps
      }
    >
      <DialogTitle>
        Create group rule
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik<FromValues>
          initialValues={{
            id: '',
            name: '',
            description: '',
          }}
          onSubmit={handleSubmit}
        >
          <Form
            id="create-rule-form"
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
              padding: `${theme.spacing(2)} 0`,
            }}
            role="form"
          >
            <Field name="id" validate={validateId}>
              {({ meta, field, form }: FieldProps<string>) => (
                <TextField
                  id="id"
                  label="ID"
                  {...field}
                  error={Boolean(meta.error)}
                  helperText={meta.error ? meta.error : undefined}
                  InputProps={{
                    endAdornment: form.isValidating ? (
                      <InputAdornment position="end">
                        <CircularProgress size="2rem" />
                      </InputAdornment>
                    ) : null,
                  }}
                />
              )}
            </Field>
            <Field name="name" validate={validateRequiredString}>
              {({ meta, field }: FieldProps<string>) => (
                <TextField
                  id="name"
                  label="Name"
                  {...field}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={
                    meta.touched && meta.error ? meta.error : undefined
                  }
                />
              )}
            </Field>
            <Field name="description" validate={validateRequiredString}>
              {({ meta, field, form }: FieldProps<string>) => (
                <>
                  <Typography variant="subtitle2">Description</Typography>
                  <RichTextEditor
                    content={replaceNewLineWithParagraph(field.value)}
                    onUpdate={({ editor }) => {
                      const value = editor.getHTML();

                      form.setFieldValue(field.name, value);
                    }}
                    s3Client={s3Client}
                  />
                  {meta.touched && meta.error && (
                    <FormHelperText error={!!meta.error}>
                      {meta.error}
                    </FormHelperText>
                  )}
                </>
              )}
            </Field>
          </Form>
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          color="primary"
          data-cy="submit-create-rule-dialog"
          type="submit"
          form="create-rule-form"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withModalManager<CreateGroupRuleDialogProps>()(
  CreateRuleDialog,
);
