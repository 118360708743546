import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';

import {
  ModalManagerProps,
  withModalManager,
} from 'components/modal-manager';
import { CUSTOM_CLASSES } from 'core/theme';
import { AppThunk, useAppDispatch } from 'core/redux';
import { Field, FieldProps, Form, Formik } from 'formik';
import { FlatPartnerGroup } from 'core/redux/reducers/partnerGroups';
import { FlatPartnerGroupRule } from 'core/redux/reducers/partnerGroupRules';
import { DEFAULT_LIST_ID } from 'constants/redux';
import { RuleValueFormValues } from 'core/redux/thunks';

interface FormikValues {
  group: FlatPartnerGroup | null;
  groupRule: FlatPartnerGroupRule | null;
  value: string;
}

export interface CreateValueDialogProps {
  schema: yup.ObjectSchema<Record<string, unknown>>;
  createThunk: (values: RuleValueFormValues, listId?: string) => AppThunk;
  groupAutocomplete: (props: FieldProps) => JSX.Element;
  groupRuleAutocomplete: (props: FieldProps) => JSX.Element;
}

export const CreateValueDialog: React.FC<
  CreateValueDialogProps & ModalManagerProps
> = ({
  isOpen,
  close,
  clear,
  schema,
  createThunk,
  groupAutocomplete,
  groupRuleAutocomplete,
}) => {
  const theme = useTheme();
  const [isSubmitting, setSubmitionState] = useState(false);
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => close(), [close]);

  const handleSubmit = useCallback(
    async (values: FormikValues) => {
      setSubmitionState(true);
      await dispatch(
        createThunk(
          {
            value: values.value,
            groupId: values.group?.id as string,
            groupRuleId: values.groupRule?.id as string,
          },
          DEFAULT_LIST_ID,
        ),
      );
      close();
    },
    [setSubmitionState, dispatch, close, createThunk],
  );

  return (
    <Dialog
      className={CUSTOM_CLASSES.DRAWER}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      transitionDuration={400}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'left',
          onExited: clear,
          mountOnEnter: true,
          unmountOnExit: true,
        } as TransitionProps
      }
    >
      <DialogTitle>
        Create rule value
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik<FormikValues>
          initialValues={{
            group: null,
            groupRule: null,
            value: '',
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={schema}
        >
          {() => {
            return (
              <Form
                id="create-value-form"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: theme.spacing(2),
                  padding: `${theme.spacing(2)} 0`,
                }}
                role="form"
              >
                <Field name="group">
                  {(props: FieldProps) => {
                    return groupAutocomplete(props);
                  }}
                </Field>
                <Field name="groupRule">
                  {(props: FieldProps) => {
                    return groupRuleAutocomplete(props);
                  }}
                </Field>
                <Field name="value">
                  {({ meta, field }: FieldProps<string>) => (
                    <TextField
                      id="value"
                      label="Value"
                      {...field}
                      error={Boolean(meta.touched && meta.error)}
                      helperText={
                        meta.touched && meta.error ? meta.error : undefined
                      }
                    />
                  )}
                </Field>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          color="primary"
          data-cy="submit-create-value-dialog"
          type="submit"
          form="create-value-form"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withModalManager<CreateValueDialogProps>()(
  CreateValueDialog,
);
