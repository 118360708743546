export const ALLOWED_S3_CONTENT_TYPE = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/tiff',
];

export const URL_PROTOCOL_REGEXP = /^(mailto:)|^((ht|f)tps?:\/\/)/g;
