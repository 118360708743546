import { apiClient } from './api';

export const uploadFile = async (file: File) => {
  const { type } = file;
  const {
    data: { data },
  } = await apiClient.generatePresignedUrl({
    contentType: type,
  });

  await apiClient.axios.put(data.uploadURL, file, {
    headers: {
      'Content-Type': type,
    },
  });

  return data.uploadURL.split('?')[0];
};
