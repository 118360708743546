import React from 'react';

import { Box, Stack } from '@mui/material';
import UserGroupsGridHeader from 'components/UserGroupsGridHeader';
import { Navigate, Route, Routes } from 'react-router-dom';
import GroupsGrid from 'components/UserGroupsGrid';
import GroupRulesGrid from 'components/UserGroupRulesGrid';
import RuleValuesGrid from 'components/UserRuleValuesGrid';
import { GroupsTabRoutes } from 'constants/routes';

const UserGroupsPage: React.FC = () => {
  return (
    <Stack sx={{ height: 'inherit' }}>
      <UserGroupsGridHeader />
      <Box flex="1 1 auto" position="relative">
        <Routes>
          <Route
            path={`/${GroupsTabRoutes.GROUPS}/*`}
            element={<GroupsGrid />}
          />
          <Route
            path={`/${GroupsTabRoutes.GROUP_RULES}/*`}
            element={<GroupRulesGrid />}
          />
          <Route
            path={`/${GroupsTabRoutes.RULE_VALUES}/*`}
            element={<RuleValuesGrid />}
          />
          <Route
            path="*"
            element={<Navigate to={GroupsTabRoutes.GROUPS} replace />}
          />
        </Routes>
      </Box>
    </Stack>
  );
};

export default UserGroupsPage;
