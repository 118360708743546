export enum ModalIDs {
  ConfirmDeletionModal = 'ConfirmDeletionModal',
  UpdateGroupDialog = 'UpdateGroupDialog',
  UpdateRuleDialog = 'UpdateRuleDialog',
  UpdateValueDialog = 'UpdateValueDialog',
  CreateGroupDialog = 'CreateGroupDialog',
  CreateRuleDialog = 'CreateRuleDialog',
  CreateValueDialog = 'CreateValueDialog',
  UpdatePartnerDialog = 'UpdatePartnerDialog',
  CreatePartnerDialog = 'CreatePartnerDialog',
  EditLink = 'EditLinkDialog',
  SelectImage = 'SelectImageDialog',
}
