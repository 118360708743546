import { ThemeOptions } from '@mui/material';

interface Blacks {
  ovBlack: string;
  black5: string;
  black20: string;
  black35: string;
  black50: string;
  black65: string;
  black80: string;
  black100: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    black: Blacks;
  }

  interface PaletteOptions {
    black: Blacks;
  }
}

export const palette: ThemeOptions['palette'] = {
  primary: {
    light: '#54d0d3',
    main: '#2AC5C9',
    dark: '#1d898c',
  },
  secondary: {
    light: '#b47aff',
    main: '#A259FF',
    dark: '#713eb2',
  },
  divider: '#E5E5E5',
  background: {
    default: '#FBFCFD',
  },
  success: {
    light: '#5EFC82',
    main: '#00C853',
    dark: '#009624',
  },
  error: {
    main: '#E53935',
  },
  black: {
    ovBlack: '#1F2124',
    black5: '#F4F4F4',
    black20: '#D2D3D3',
    black35: '#B1B1B2',
    black50: '#8F9091',
    black65: '#6D6F71',
    black80: '#4C4D50',
    black100: '#1F2124',
  },
};
