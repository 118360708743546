import React, { useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useModalManager } from './modal-manager';
import { ModalIDs } from 'constants/modals';
import { CreateGroupRuleDialogProps } from './modals/CreateRuleDialog';
import { Button } from '@mui/material';

const NewRuleButton: React.FC<CreateGroupRuleDialogProps> = ({
  idSchema,
  createThunk,
}) => {
  const { openModal } = useModalManager();
  const handleClick = useCallback(() => {
    openModal<CreateGroupRuleDialogProps, void>(
      ModalIDs.CreateRuleDialog,
      { idSchema, createThunk },
    );
  }, [openModal, idSchema, createThunk]);
  return (
    <Button
      startIcon={<AddIcon />}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      New Group Rule
    </Button>
  );
};

export default NewRuleButton;
