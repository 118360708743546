import { readAsList } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import PartnerGroups, {
  FlatPartnerGroup,
} from 'core/redux/reducers/partnerGroups';

export const createPartnerGroup =
  (
    attributes: Pick<FlatPartnerGroup, 'name' | 'description' | 'id'>,
    listId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const apiResponse = await apiClient.postPartnerGroup({
        ...attributes,
        lid: attributes.id,
      });

      const { entities } = readAsList(apiResponse.data);
      const { PartnerGroup } = entities;

      if (PartnerGroup) {
        dispatch(PartnerGroups.actions.update(PartnerGroup[0], listId));
      }
    } catch (error) {
      console.error(error);
    }
  };
