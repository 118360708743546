import { Button } from '@mui/material';
import { auth } from '@one-vision/login';
import React from 'react';

const Home: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        minHeight: '100%',
        minWidth: '100%',
      }}
    >
      <Button
        variant="contained"
        onClick={auth.signOut.bind(auth)}
        disabled={!auth.authorized}
      >
        Log Out
      </Button>
    </div>
  );
};

export default Home;
