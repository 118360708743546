import { createEntityReducer } from '../createEntityReducer';
import { IdentifiableChunk } from '../types';
import {
  Flat,
  PartnerGroupRuleJsonApiEntity,
} from '@one-vision/json-api-parser';

export type FlatPartnerGroupRule = Flat<PartnerGroupRuleJsonApiEntity>;

const PartnerGroupRules = createEntityReducer<
  FlatPartnerGroupRule,
  'id',
  'partnerGroupRule'
>({
  entityName: 'partnerGroupRule',
  entityIdProperty: 'id',
  idSelector: (partnerGroupRule) => partnerGroupRule.id,
});

export type PartnerGroupRuleChunk = IdentifiableChunk<
  FlatPartnerGroupRule,
  'id'
>;

export default PartnerGroupRules;
