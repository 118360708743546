import {
  AgGridReact,
  AgGridReactProps,
  AgReactUiProps,
} from 'ag-grid-react';
import React from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import GridLoader from './GridLoader';

type Props<TData> = (AgGridReactProps<TData> | AgReactUiProps<TData>) & {
  isLoading?: boolean;
};

const AppGrid = <TData,>({
  rowData,
  defaultColDef,
  columnDefs,
  isLoading = false,
  onCellClicked,
}: Props<TData>): JSX.Element => {
  return (
    <>
      <AgGridReact
        className="ag-theme-alpine"
        animateRows
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        onCellClicked={onCellClicked}
      />
      {isLoading && <GridLoader />}
    </>
  );
};

export default AppGrid;
