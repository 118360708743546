import { readAsList } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import UserGroupRules, {
  FlatUserGroupRule,
} from 'core/redux/reducers/userGroupRules';

export const createUserGroupRule =
  (
    attributes: Pick<FlatUserGroupRule, 'name' | 'description' | 'id'>,
    listId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const apiResponse = await apiClient.postUserGroupRule({
        ...attributes,
        lid: attributes.id,
      });

      const { entities } = readAsList(apiResponse.data);
      const { UserGroupRule } = entities;

      if (UserGroupRule) {
        dispatch(UserGroupRules.actions.update(UserGroupRule[0], listId));
      }
    } catch (error) {
      console.error(error);
    }
  };
