import * as yup from 'yup';
import { VALID_DATE, VALID_EMAIL } from 'constants/text';

export const schema = yup.object({
  currentPartner: yup.string().nullable(),
  businessName: yup
    .string()
    .nullable()
    .transform((value) => value?.trim() || null),
  address: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  zip: yup.string().nullable(),
  timeZone: yup.string().nullable(),
  contractSignedDate: yup.date().typeError(VALID_DATE).nullable(),
  supportLiveDate: yup.date().typeError(VALID_DATE).nullable(),
  marketingLaunchDate: yup.date().typeError(VALID_DATE).nullable(),
  litsDate: yup.date().typeError(VALID_DATE).nullable(),
  supportPhone: yup.string().nullable(),
  // TO-DO: clear DB phones with text
  // .matches(PHONE_PATTERN_REG, VALID_PHONE),
  supportEmail: yup.string().email(VALID_EMAIL).nullable(),
  signaturePhone: yup.string().nullable(),
  // .matches(PHONE_PATTERN_REG, VALID_PHONE),
  signatureEmail: yup.string().email(VALID_EMAIL).nullable(),
  membershipEmail: yup.string().email(VALID_EMAIL).nullable(),
  membershipSalesCalendlyLink: yup.string().nullable(),
  membershipUrlNew: yup.string().nullable(),
  membershipUrlComNew: yup.string().nullable(),
  websiteUrl: yup.string().nullable(),
  zendeskUrl: yup.string().nullable(),
  zendeskDomain: yup.string().nullable(),
  slackTeamLink: yup.string().nullable(),
  billingEmail: yup.string().email(VALID_EMAIL).nullable(),
  billingEmail2: yup.string().email(VALID_EMAIL).nullable(),
  buyNow: yup.string().nullable(),
  tos: yup.string().nullable(),
  annual: yup.boolean().nullable(),
  notionBoardUrl: yup.string().nullable(),
  docsendUrl: yup.string().nullable(),
  stripeAccountId: yup.string().nullable(),
  stripeCustomerId: yup.string().nullable(),
  iterableTestUserId: yup.string().nullable(),
  globalNotificationEmail: yup.string().email(VALID_EMAIL).nullable(),
});
