import React, { useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material';
import { ChainedCommands, EditorContentProps } from '@tiptap/react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import RuleIcon from '@mui/icons-material/Rule';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CodeIcon from '@mui/icons-material/Code';
// import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { MenuButton } from './menu-button.view';
import { useModalManager } from 'components/modal-manager';
import { ModalIDs } from 'constants/modals';
import { URL_PROTOCOL_REGEXP } from 'constants/textEditor';

interface EditLinkDialogProps {
  url?: string;
  text?: string;
  textInput?: boolean;
}

interface DzSelectImageDialogResponse {
  url: string;
  file: File | null;
}

type ToggleCommands = Extract<
  keyof ChainedCommands,
  | 'toggleBold'
  | 'toggleItalic'
  | 'toggleUnderline'
  | 'toggleStrike'
  | 'toggleTaskList'
  | 'toggleCodeBlock'
>;

interface EditorMenuProps {
  uploadImage: (file: File) => Promise<string>;
}

export const EditorMenu: React.FC<
  EditorContentProps & EditorMenuProps
> = ({ editor, uploadImage, ...rest }) => {
  const theme = useTheme();
  const { openModal } = useModalManager();
  const setLink = useCallback(async () => {
    if (!editor) {
      return;
    }

    const previousUrl =
      (editor.getAttributes('link').href as string) || '';

    // select whole link if one exists
    if (previousUrl) {
      editor.commands.extendMarkRange('link');
    }

    const { from, to } = editor.state.selection;
    const previousText = editor.state.doc.textBetween(from, to);

    const linkEditResult = await openModal<
      EditLinkDialogProps,
      Required<EditLinkDialogProps>
    >(ModalIDs.EditLink, {
      text: previousText,
      url: previousUrl,
    });

    // cancelled
    if (!linkEditResult) {
      editor.commands.focus();
      return;
    }

    const { url, text } = linkEditResult;

    // empty
    if (url === '') {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .setTextSelection(editor.state.selection.to)
        .run();

      return;
    }

    // update text
    if (previousText !== text) {
      if (text) {
        editor
          .chain()
          .focus()
          .insertContent(text)
          .setTextSelection({
            from,
            to: from + text.length,
          })
          .run();
      } else {
        editor.chain().focus().deleteSelection().run();
      }
    }

    if (editor.state.selection.from === editor.state.selection.to) {
      editor
        .chain()
        .focus()
        .insertContent(url)
        .setTextSelection({
          from: editor.state.selection.from,
          to: editor.state.selection.from + url.length,
        })
        .run();
    }

    const hasProtocol = url.match(URL_PROTOCOL_REGEXP);

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: hasProtocol ? url : `http://${url}` })
      .setTextSelection(editor.state.selection.to)
      .run();
  }, [editor, openModal]);

  const _chooseImage = useCallback(async () => {
    if (!editor) {
      return;
    }

    const result = await openModal<unknown, DzSelectImageDialogResponse>(
      ModalIDs.SelectImage,
      {},
    );

    editor.commands.focus();
    if (!result) {
      return;
    }

    const { file, url } = result;

    const src = file ? await uploadImage(file) : url;

    if (src) {
      editor.commands.setImage({ src });
    }
  }, [editor, openModal, uploadImage]);

  const toggle = useCallback(
    (command: ToggleCommands) => () => {
      if (!editor) {
        return;
      }

      editor.chain().focus()?.[command]().run();
    },
    [editor],
  );

  const toggleBold = useMemo(() => toggle('toggleBold'), [toggle]);
  const toggleItalic = useMemo(() => toggle('toggleItalic'), [toggle]);
  const toggleUnderline = useMemo(
    () => toggle('toggleUnderline'),
    [toggle],
  );
  const toggleStrike = useMemo(() => toggle('toggleStrike'), [toggle]);
  const toggleTaskList = useMemo(() => toggle('toggleTaskList'), [toggle]);
  const toggleCodeBlock = useMemo(
    () => toggle('toggleCodeBlock'),
    [toggle],
  );

  if (!editor) {
    return null;
  }

  return (
    <div
      {...rest}
      style={{
        padding: theme.spacing(1),
        display: 'flex',
        columnGap: theme.spacing(2),
        borderBottom: '1px solid #9E9E9E',
        flexWrap: 'wrap',
      }}
    >
      <MenuButton
        onClick={toggleBold}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        isActive={editor.isActive('bold')}
      >
        <FormatBoldIcon />
      </MenuButton>
      <MenuButton
        onClick={toggleItalic}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        isActive={editor.isActive('italic')}
      >
        <FormatItalicIcon />
      </MenuButton>
      <MenuButton
        onClick={toggleUnderline}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        isActive={editor.isActive('underline')}
      >
        <FormatUnderlinedIcon />
      </MenuButton>
      <MenuButton
        onClick={toggleStrike}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        isActive={editor.isActive('strike')}
      >
        <FormatStrikethroughIcon />
      </MenuButton>
      <MenuButton
        onClick={toggleTaskList}
        disabled={!editor.can().chain().focus().toggleTaskList().run()}
        isActive={editor.isActive('taskList')}
      >
        <RuleIcon />
      </MenuButton>
      <MenuButton onClick={setLink} isActive={editor.isActive('link')}>
        <InsertLinkIcon />
      </MenuButton>
      {/*To-do: add option to add images for admin in AWS*/}
      {/* <MenuButton onClick={chooseImage}>
        <WallpaperIcon />
      </MenuButton> */}
      <MenuButton
        onClick={toggleCodeBlock}
        isActive={editor.isActive('codeBlock')}
      >
        <CodeIcon />
      </MenuButton>
    </div>
  );
};
