import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  EditorContent as EditorContentComp,
  EditorContentProps,
} from '@tiptap/react';

const useStyles = makeStyles()((theme) => ({
  editorContent: {
    '& > *': {
      padding: `0 ${theme.spacing(2)}`,
      overflow: 'auto',
    },
  },
}));

export const EditorContent: React.FC<EditorContentProps> = ({
  editor,
  ref,
  ...rest
}) => {
  const { classes } = useStyles();
  return (
    <EditorContentComp
      editor={editor}
      {...rest}
      className={classes.editorContent}
    />
  );
};
