import React from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AppNavBar from 'components/AppNavBar';
import Modals from 'components/modals/Modals';
import { Snackbar } from 'components/shared/Snackbar';
import Pages from 'Pages';

const useStyles = makeStyles()((theme) => ({
  app: {
    position: 'fixed',
    display: 'grid',
    gridTemplateColumns:
      '[sidebar-start] auto [sidebar-end main-start] 1fr [main-end]',
    gridTemplateRows: '[main-start] 1fr [main-end]',
    height: 'auto',
    minHeight: '100vh',
    width: '100vw',
    minWidth: '600px',
  },
  main: {
    backgroundColor: theme.palette.black.black5,
    padding: theme.spacing(),
    gridRow: 'main-start / main-end',
    gridColumn: 'main-start / main-end',
  },
  canvas: {
    height: '100%',
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
}));

const Root: React.FC = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.app}>
      <AppNavBar />
      <main className={classes.main}>
        <Paper className={classes.canvas}>
          <Pages />
        </Paper>
      </main>
      <Modals />
      <Snackbar />
    </div>
  );
};

export default Root;
