import { CircularProgress } from '@mui/material';
import React from 'react';

const GridLoader: React.FC = () => {
  return (
    <CircularProgress
      sx={{
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      }}
    />
  );
};

export default GridLoader;
