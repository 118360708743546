import {
  Autocomplete,
  AutocompleteProps,
  ChipTypeMap,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { GROUP_AUTOCOMPLETE } from 'constants/redux';
import { useAppDispatch, useAppSelector } from 'core/redux';
import PartnerGroups from 'core/redux/reducers/partnerGroups';
import { fetchPartnerGroups } from 'core/redux/thunks';
import React, { useEffect } from 'react';

interface Props<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> {
  AutocompleteProps: Omit<
    AutocompleteProps<
      { name: string; id: string },
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    'renderInput' | 'options'
  >;
  TextFieldProps?: TextFieldProps;
}

function PartnerGroupAutocomplete<M extends boolean | undefined>({
  AutocompleteProps,
  TextFieldProps = {},
}: Props<M>) {
  const dispatch = useAppDispatch();
  const { isReady } = useAppSelector((state) =>
    PartnerGroups.selectors.selectList(state, GROUP_AUTOCOMPLETE),
  );

  useEffect(() => {
    if (isReady) return;
    dispatch(fetchPartnerGroups({ page: 1 }, GROUP_AUTOCOMPLETE));
  }, [isReady, dispatch]);

  const options = useAppSelector(
    (state) =>
      PartnerGroups.selectors.selectEntitiesFromList(
        state,
        GROUP_AUTOCOMPLETE,
      ) as { name: string; id: string }[],
  );
  return (
    <Autocomplete
      id="group"
      {...AutocompleteProps}
      loading={!isReady}
      options={options}
      getOptionLabel={(option) => option.name || option.id.toString()}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          label="Partner Group"
          margin="none"
        />
      )}
    />
  );
}

export default PartnerGroupAutocomplete;
