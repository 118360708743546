import { DEFAULT_LIST_ID } from 'constants/redux';
import { useAppDispatch, useAppSelector } from 'core/redux';
import Partners, { FlatPartner } from 'core/redux/reducers/partners';
import { fetchPartners } from 'core/redux/thunks/partners';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import AppGrid from './AppGrid';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { useModalManager } from './modal-manager';
import { Chip, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ModalIDs } from 'constants/modals';
import { UpdatePartnerDialogProps } from './modals/UpdatePartnerDialog';
import { useRelatedGroupsSelector } from 'hooks/useRelatedGroupsSelector';
import { YES } from 'constants/stringBooleans';

export enum PartnerColumnsName {
  id = 'ID',
  businessName = 'Name',
  partnerGroup = 'Groups',
  actions = 'Actions',
}

const ActionsMenu: React.FC<{ data: FlatPartner }> = ({ data }) => {
  const buttonRef = useRef(null);
  const [open, setOpenState] = useState(false);
  const { openModal } = useModalManager();
  const handleOpen = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      setOpenState(true);
    },
    [setOpenState],
  );
  const handleClose = useCallback(
    () => setOpenState(false),
    [setOpenState],
  );
  const handleUpdate = useCallback(async () => {
    handleClose();
    openModal<UpdatePartnerDialogProps, void>(
      ModalIDs.UpdatePartnerDialog,
      { partnerId: data.id },
    );
  }, [handleClose, openModal, data]);
  return (
    <>
      <IconButton
        ref={buttonRef}
        aria-haspopup="menu"
        aria-controls="actions-menu"
        aria-expanded={open}
        onClick={handleOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        open={open}
        anchorEl={buttonRef.current}
        onClose={handleClose}
      >
        <MenuItem onClick={handleUpdate}>Update</MenuItem>
      </Menu>
    </>
  );
};

const defaultColDef: ColDef = {
  resizable: true,
  sortable: false,
};

const getColumnDefs = () => {
  const columnDefs: ColDef<FlatPartner>[] = [
    {
      headerName: PartnerColumnsName.id,
      field: 'id',
    },
    {
      headerName: PartnerColumnsName.businessName,
      field: 'businessName',
    },
    {
      headerName: PartnerColumnsName.partnerGroup,
      cellRenderer: function Renderer({ data }: { data: FlatPartner }) {
        const groups = useRelatedGroupsSelector(data.relationships);

        return groups.map((group) => (
          <Chip key={group.id} label={group.name} color="primary" />
        ));
      },
      flex: 1,
    },
    {
      headerName: PartnerColumnsName.actions,
      cellRenderer: ActionsMenu,
    },
  ];

  return columnDefs;
};

const PartnersGrid: React.FC<{
  onCellClicked: (e: CellClickedEvent) => void;
  searchText: string;
}> = ({ onCellClicked, searchText }) => {
  const rowData = useAppSelector(
    (state) =>
      Partners.selectors.selectEntitiesFromList(
        state,
        DEFAULT_LIST_ID,
      ) as FlatPartner[],
  );
  const listState = useAppSelector((state) =>
    Partners.selectors.selectList(state, DEFAULT_LIST_ID),
  );

  const partnersList = useMemo(
    () =>
      rowData.filter((client) => {
        if (!searchText) {
          return true;
        }
        const searchTextLowerCase = searchText.toLowerCase();
        return Boolean(
          Object.values(client).find((value: string) =>
            value?.toString().toLowerCase().includes(searchTextLowerCase),
          ),
        );
      }),
    [rowData, searchText],
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      fetchPartners(
        {
          page: 1,
          perPage: 150,
          include: ['partnerGroup'],
          sort: ['businessName'],
          filter: {
            currentPartner: YES,
          },
        },
        DEFAULT_LIST_ID,
      ),
    );
  }, [dispatch]);

  const sortedRows = useMemo(() => {
    return partnersList
      .slice()
      .sort((a, b) =>
        a.businessName.toLocaleLowerCase() <
        b.businessName.toLocaleLowerCase()
          ? -1
          : 0,
      );
  }, [partnersList]);
  return (
    <AppGrid
      rowData={sortedRows}
      defaultColDef={defaultColDef}
      columnDefs={getColumnDefs()}
      isLoading={!listState.isReady}
      onCellClicked={onCellClicked}
      suppressColumnVirtualisation
    />
  );
};

export default PartnersGrid;
