import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import { UserGroup } from 'types';
import UserGroups, { FlatUserGroup } from 'core/redux/reducers/userGroups';

export const updateUserGroup =
  (
    updates: Pick<FlatUserGroup, 'id'> & Partial<FlatUserGroup>,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const result = await apiClient.updateUserGroup(updates);
      const {
        data: { data },
      } = result;
      const userGroup = data.reduce<UserGroup[]>((acc, group) => {
        return [
          ...acc,
          {
            id: String(group.id),
            ...group.attributes,
          },
        ];
      }, []);

      if (userGroup) {
        dispatch(
          UserGroups.actions.batch(
            userGroup.map((group) => UserGroups.actions.update(group)),
          ),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
