import {
  Autocomplete,
  AutocompleteProps,
  ChipTypeMap,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { RULE_AUTOCOMPLETE } from 'constants/redux';
import { useAppDispatch, useAppSelector } from 'core/redux';
import partnerGroupRules, {
  FlatPartnerGroupRule,
} from 'core/redux/reducers/partnerGroupRules';
import { fetchPartnerGroupRules } from 'core/redux/thunks';
import React, { useEffect } from 'react';

interface Props<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> {
  AutocompleteProps: Omit<
    AutocompleteProps<
      FlatPartnerGroupRule,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    'renderInput' | 'options'
  >;
  TextFieldProps?: TextFieldProps;
}

const GroupRuleAutocomplete: React.FC<Props> = ({
  AutocompleteProps,
  TextFieldProps = {},
}) => {
  const dispatch = useAppDispatch();
  const { isReady } = useAppSelector((state) =>
    partnerGroupRules.selectors.selectList(state, RULE_AUTOCOMPLETE),
  );

  useEffect(() => {
    if (isReady) return;
    dispatch(fetchPartnerGroupRules({ page: 1 }, RULE_AUTOCOMPLETE));
  }, [isReady, dispatch]);

  const options = useAppSelector(
    (state) =>
      partnerGroupRules.selectors.selectEntitiesFromList(
        state,
        RULE_AUTOCOMPLETE,
      ) as FlatPartnerGroupRule[],
  );
  return (
    <Autocomplete
      id="rule"
      {...AutocompleteProps}
      loading={!isReady}
      options={options}
      getOptionLabel={(option) => option.name || option.id.toString()}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          label="Group Rule"
          margin="none"
        />
      )}
    />
  );
};

export default GroupRuleAutocomplete;
