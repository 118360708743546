import { ThemeOptions } from '@mui/material';

export enum CUSTOM_CLASSES {
  DRAWER = 'drawer',
}

export const components: ThemeOptions['components'] = {
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        [`.MuiDialog-root.${CUSTOM_CLASSES.DRAWER} &`]: {
          margin: 0,
          borderRadius: `${theme.spacing(0.5)} 0px 0px ${theme.spacing(
            0.5,
          )}`,
          height: '100vh',
          maxHeight: '100vh',
          width: '56rem',
        },
      }),
      container: {
        [`.MuiDialog-root.${CUSTOM_CLASSES.DRAWER} &`]: {
          justifyContent: 'flex-end',
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`.MuiDialog-root.${CUSTOM_CLASSES.DRAWER} &`]: {
          backgroundColor: theme.palette.secondary.main,
          color: '#fff',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`.MuiDialog-root.${CUSTOM_CLASSES.DRAWER} &`]: {
          padding: theme.spacing(3),
          backgroundColor: theme.palette.black.black5,
        },
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: '1.2rem',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        margin: '-1px 0',
        border: '1px solid #C4C4C4',
        borderRadius: '0',
      },
      listbox: {
        padding: '0',
      },
      option: ({ theme }) => ({
        minHeight: theme.spacing(6),
        color: theme.palette.primary.main,
        '&[data-focus="true"]': {
          backgroundColor: '#EAF9FA',
        },
        whiteSpace: 'nowrap',
        '&.create:last-child': {
          borderTop: '1px solid #C4C4C4',
          backgroundColor: theme.palette.black.black5,
        },
        '&.create.Mui-focused:last-child': {
          backgroundColor: theme.palette.black.black20,
        },
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      contained: {
        color: '#fff',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontSize: 'unset',
      },
    },
  },
};
