import React from 'react';
import { Button, ButtonProps, useTheme } from '@mui/material';

interface MenuButtonProps {
  children: React.ReactNode;
  isActive?: boolean;
}

export const MenuButton: React.FC<MenuButtonProps & ButtonProps> = ({
  children,
  isActive = false,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Button
      color="inherit"
      variant={isActive ? 'contained' : 'text'}
      sx={{
        padding: 0,
        height: theme.spacing(3),
        minWidth: theme.spacing(3),
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
