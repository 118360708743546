import React, { useCallback, useEffect } from 'react';
import { useEditor, EditorOptions } from '@tiptap/react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import StarterKit from '@tiptap/starter-kit';
import { Underline } from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Image from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorContent } from './editor-content.view';
import { EditorMenu } from './editor-menu.view';
import { S3Client } from '../../types';
import { logError } from '@one-vision/utils';
import { EditorView } from '@tiptap/pm/view';
import { Slice } from '@tiptap/pm/model';
import { ALLOWED_S3_CONTENT_TYPE } from 'constants/textEditor';

const useStyles = makeStyles<{ placeholder: string }>()(() => ({
  proseMirror: {
    minHeight: '8rem',
    '& > p': {
      display: 'block',
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      marginInlineStart: '0px',
      marginInlineEnd: '0px',
    },
  },
  richTaskList: {
    listStyle: 'none',
    padding: 0,

    '& p': {
      margin: 0,
    },
  },
  richTaskItem: {
    display: 'flex',
    '& > label': {
      flex: '0 0 auto',
      marginRight: '0.5rem',
      userSelect: 'none',
    },

    '& > div': {
      flex: '1 1 auto',
    },
  },
  codeBlock: {
    background: '#0d0d0d',
    borderRadius: '0.5rem',
    color: '#fff',
    fontFamily: '"JetBrainsMono", monospace',
    padding: '0.75rem 1rem',

    '& code': {
      background: 'none',
      color: 'inherit',
      fontSize: '0.8rem',
      padding: 0,
    },
  },
  richLink: {
    cursor: 'pointer',
  },
  blockQuote: {
    borderLeft: '3px solid rgba(13, 13, 13, 0.1)',
    paddingLeft: '1rem',
  },
  richImage: {
    height: 'auto',
    maxWidth: '100%',

    '&.ProseMirror-selectednode': {
      outline: '3px solid #68cef8',
    },

    '&:first-child': {
      marginTop: '1rem',
    },
  },
  emptyEditor: {
    '&::before': {
      color: '#adb5bd',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
  },
}));

interface RickTextEditorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorHookDependency?: any;
  content?: EditorOptions['content'];
  onUpdate?: EditorOptions['onUpdate'];
  placeholder?: string;
  withMenu?: boolean;
  editable?: boolean;
  s3Client: S3Client;
  customClasses?: ReturnType<typeof useStyles>;
}

export const RichTextEditor: React.FC<RickTextEditorProps> = ({
  content = '',
  onUpdate = () => undefined,
  placeholder = '',
  withMenu = true,
  editable = true,
  editorHookDependency,
  s3Client,
}) => {
  const { classes } = useStyles({ placeholder });

  const uploadImage = useCallback(
    async (file: File) => {
      if (!ALLOWED_S3_CONTENT_TYPE.includes(file.type)) {
        throw new Error('File type is not supported');
      }
      if (file.size / 1024 / 1024 > 5) {
        throw new Error('Image is too big');
      }
      const imageUrl = await s3Client.uploadFile(file);

      return imageUrl;
    },
    [s3Client],
  );

  const handleDrop = useCallback(
    (
      view: EditorView,
      event: DragEvent,
      _: Slice,
      moved: boolean,
    ): boolean => {
      if (
        !moved &&
        event.dataTransfer &&
        event.dataTransfer.files &&
        event.dataTransfer.files[0]
      ) {
        uploadImage(event.dataTransfer.files[0])
          .then((src) => {
            const { schema } = view.state;
            const coordinates = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            });
            if (!coordinates) {
              throw new Error('Position is outside the editor');
            }
            const node = schema.nodes.image.create({ src });
            const transaction = view.state.tr.insert(
              coordinates.pos,
              node,
            );
            return view.dispatch(transaction);
          })
          .catch((error) => {
            logError(error);
          });
        return true;
      }

      return false;
    },
    [uploadImage],
  );

  const editor = useEditor(
    {
      editable,
      extensions: [
        StarterKit.configure({
          codeBlock: {
            HTMLAttributes: {
              class: classes.codeBlock,
            },
          },
          blockquote: {
            HTMLAttributes: {
              class: classes.blockQuote,
            },
          },
        }),
        Underline,
        TaskList.configure({
          HTMLAttributes: {
            class: classes.richTaskList,
          },
        }),
        TaskItem.configure({
          nested: true,
          HTMLAttributes: {
            class: classes.richTaskItem,
          },
        }),
        Link.configure({
          protocols: ['ftp', 'mailto'],
          autolink: true,
          HTMLAttributes: {
            class: classes.richLink,
          },
        }),
        Image.configure({
          HTMLAttributes: {
            class: classes.richImage,
          },
        }),
        Placeholder.configure({
          placeholder: 'My Custom Placeholder',
          emptyEditorClass: classes.emptyEditor,
        }),
      ],
      onUpdate,
      content,
      editorProps: {
        attributes: {
          class: classes.proseMirror,
        },
        handleDrop,
      },
    },
    [editorHookDependency, editable, handleDrop],
  );

  useEffect(() => {
    if (!editor) {
      return;
    }
    editor.setOptions({
      editorProps: {
        handlePaste: function (_, event) {
          if (event.clipboardData && event.clipboardData.files[0]) {
            uploadImage(event.clipboardData.files[0]).then((src) => {
              editor.commands.setImage({ src });
            });
            return true;
          }
          return false;
        },
        handleDrop,
      },
    });
  }, [editor, uploadImage, handleDrop]);

  return (
    <Box sx={{ border: '1px solid #9E9E9E', borderRadius: '4px 4px 0 0' }}>
      {withMenu && (
        <EditorMenu editor={editor} uploadImage={uploadImage} />
      )}
      <EditorContent editor={editor} />
    </Box>
  );
};
