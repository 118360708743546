import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

import {
  ModalManagerProps,
  withModalManager,
} from 'components/modal-manager';
import { CUSTOM_CLASSES } from 'core/theme';
import { useAppDispatch, useAppSelector } from 'core/redux';
import { Field, FieldProps, Form, Formik } from 'formik';
import Partners, { FlatPartner } from 'core/redux/reducers/partners';
import { JsonApiId } from '@one-vision/json-api-parser';
import { FlatPartnerGroup } from 'core/redux/reducers/partnerGroups';
import PartnerGroupAutocomplete from 'components/PartnerGroupAutocomplete';
import { updateAssociatedGroups } from 'core/redux/thunks/partners';
import { useRelatedGroupsSelector } from 'hooks/useRelatedGroupsSelector';

export interface UpdatePartnerDialogProps {
  partnerId: JsonApiId;
}

interface FromValues {
  groups: FlatPartnerGroup[];
}

export const UpdatePartnerDialog: React.FC<
  UpdatePartnerDialogProps & ModalManagerProps
> = ({ isOpen, close, clear, partnerId }) => {
  const theme = useTheme();
  const [isSubmitting, setSubmitionState] = useState(false);
  const dispatch = useAppDispatch();
  const partner = useAppSelector(
    (state) =>
      Partners.selectors.selectById(
        state,
        partnerId as string,
      ) as FlatPartner,
  );
  const groups = useRelatedGroupsSelector(partner.relationships);
  const onClose = useCallback(() => close(), [close]);

  const handleSubmit = useCallback(
    async ({ groups }: FromValues) => {
      setSubmitionState(true);
      await dispatch(updateAssociatedGroups(partnerId, groups));
      close();
    },
    [setSubmitionState, dispatch, partnerId, close],
  );

  return (
    <Dialog
      className={CUSTOM_CLASSES.DRAWER}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      transitionDuration={400}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'left',
          onExited: clear,
          mountOnEnter: true,
          unmountOnExit: true,
        } as TransitionProps
      }
    >
      <DialogTitle>
        Update associated groups
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik<FromValues>
          initialValues={{
            groups,
          }}
          onSubmit={handleSubmit}
        >
          <Form
            id="update-partner-groups-form"
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
              padding: `${theme.spacing(2)} 0`,
            }}
            role="form"
          >
            <Field name="groups">
              {({ field, meta, form }: FieldProps) => {
                return (
                  <PartnerGroupAutocomplete
                    AutocompleteProps={{
                      value: field.value,
                      disableCloseOnSelect: true,
                      multiple: true,
                      onChange: (_: unknown, value) => {
                        form.setFieldValue(field.name, value);
                      },
                      isOptionEqualToValue: (option, value) => {
                        return option.id === value.id;
                      },
                    }}
                    TextFieldProps={{
                      error: Boolean(meta.touched && meta.error),
                      helperText:
                        meta.touched && meta.error
                          ? meta.error
                          : undefined,
                    }}
                  />
                );
              }}
            </Field>
          </Form>
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          color="primary"
          data-cy="submit-update-partner-groups-form"
          type="submit"
          form="update-partner-groups-form"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withModalManager<UpdatePartnerDialogProps>()(
  UpdatePartnerDialog,
);
