import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/index.css';
import './assets/roboto.css';
import { OvLogin, authProvider } from '@one-vision/login';
import { loginLogo } from 'assets';
import { config } from 'core/config';

authProvider.setup({
  cognitoClientId: config.cognitoClientId,
  cognitoUserPoolId: config.cognitoUserPoolId,
  cognitoUserPoolRegion: config.cognitoUserPoolRegion,
  isUat: config.stage === 'uat',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <OvLogin app={App} logo={loginLogo} withSignUp={false} />
  </React.StrictMode>,
);
