import { Box, InputAdornment, TextField, useTheme } from '@mui/material';

import React from 'react';
import GroupsTabs from './GroupsTabs';
import SearchIcon from '@mui/icons-material/Search';
import { GroupsTabRoutes } from 'constants/routes';
import NewGroupButton from './NewGroupButton';
import NewRuleButton from './NewRuleButton';
import NewValueButton from './NewValueButton';
import { Routes, Route } from 'react-router-dom';
import * as yup from 'yup';
import { createUserGroup } from 'core/redux/thunks';
import { apiClient } from 'core/api';
import { FieldProps } from 'formik';
import { createUserGroupRule } from 'core/redux/thunks';
import { createUserRuleValue } from 'core/redux/thunks';
import UserGroupAutocomplete from './UserGroupAutocomplete';
import UserGroupRuleAutocomplete from './UserGroupRuleAutocomplete';

const checkForNonExistingRuleValue = async ({
  userGroupId,
  userGroupRuleId,
}: {
  userGroupId: string;
  userGroupRuleId: string;
}) => {
  if (!userGroupId || !userGroupRuleId) return true;
  try {
    const result = await apiClient.getUserRuleValues({
      filter: {
        userGroupId,
        userGroupRuleId,
      },
    });

    const {
      data: { data },
    } = result;

    return !data.length;
  } catch (error) {
    console.error(error);
    return true;
  }
};

const valueValidationSchema = yup.object({
  group: yup
    .object({
      id: yup.string().required(),
    })
    .required('Please, select user Group')
    .test(
      'isAlreadySet',
      'This group already has a value for the current rule. Please, change group or rule.',
      async (group, { parent }) => {
        const { groupRule } = parent;

        return checkForNonExistingRuleValue({
          userGroupId: group?.id,
          userGroupRuleId: groupRule?.id || '',
        });
      },
    ),
  groupRule: yup
    .object({
      id: yup.string().required(),
    })
    .required('Please, select user Group Rule')
    .test(
      'isAlreadySet',
      'This rule already has a value for the current group. Please, change group or rule.',
      async (groupRule, { parent }) => {
        const { group } = parent;

        return checkForNonExistingRuleValue({
          userGroupId: group?.id,
          userGroupRuleId: groupRule?.id || '',
        });
      },
    ),
  value: yup.string().required('Please, provide a value'),
});

const checkRuleIdNonExistenceSchema = yup
  .string()
  .test(
    'testForDuplicates',
    'Group with this ID already exists',
    async (value) => {
      const result = await apiClient.getUserGroupRule({
        filter: {
          userGroupRuleId: value as string,
        },
      });
      const {
        data: { data },
      } = result;

      return !data.length;
    },
  );

const checkGroupIdNonExistenceSchema = yup
  .string()
  .test(
    'testForDuplicates',
    'Group with this ID already exists',
    async (value) => {
      const result = await apiClient.getUserGroup({
        filter: {
          userGroupId: value as string,
        },
      });
      const {
        data: { data },
      } = result;

      return !data.length;
    },
  );

const groupAutocomplete = ({ field, form, meta }: FieldProps) => (
  <UserGroupAutocomplete
    AutocompleteProps={{
      id: field.name,
      value: field.value,
      isOptionEqualToValue: (option) => {
        return option.id === field.value.id;
      },
      onChange: (_: unknown, value) => {
        if (!value) {
          form.setFieldValue(field.name, null);
          return;
        }

        form.setFieldValue(field.name, value);
      },
    }}
    TextFieldProps={{
      error: Boolean(meta.touched && meta.error),
      helperText: meta.touched && meta.error ? meta.error : undefined,
      onBlur: field.onBlur,
    }}
  />
);
const groupRuleAutocomplete = ({ field, form, meta }: FieldProps) => (
  <UserGroupRuleAutocomplete
    AutocompleteProps={{
      id: field.name,
      value: field.value,
      isOptionEqualToValue: (option) => {
        return option.id === field.value.id;
      },
      onChange: (_: unknown, value) => {
        if (!value) {
          form.setFieldValue(field.name, null);
          return;
        }

        form.setFieldValue(field.name, value);
      },
    }}
    TextFieldProps={{
      error: Boolean(meta.touched && meta.error),
      helperText: meta.touched && meta.error ? meta.error : undefined,
      onBlur: field.onBlur,
    }}
  />
);

const GroupsGridHeader: React.FC = () => {
  const pagesList = [
    {
      url: `/${GroupsTabRoutes.GROUPS}/*`,
      content: (
        <NewGroupButton
          idSchema={checkGroupIdNonExistenceSchema}
          createThunk={createUserGroup}
          type="user"
        />
      ),
    },
    {
      url: `/${GroupsTabRoutes.GROUP_RULES}/*`,
      content: (
        <NewRuleButton
          idSchema={checkRuleIdNonExistenceSchema}
          createThunk={createUserGroupRule}
        />
      ),
    },
    {
      url: `/${GroupsTabRoutes.RULE_VALUES}/*`,
      content: (
        <NewValueButton
          schema={valueValidationSchema}
          createThunk={createUserRuleValue}
          groupAutocomplete={groupAutocomplete}
          groupRuleAutocomplete={groupRuleAutocomplete}
        />
      ),
    },
  ];
  const theme = useTheme();

  return (
    <Box
      flex="0 1 auto"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <GroupsTabs />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginRight: theme.spacing(8),
          columnGap: theme.spacing(2),
        }}
      >
        <TextField
          variant="standard"
          placeholder="Filter results"
          margin="none"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Routes>
          {pagesList.map((el) => (
            <Route
              key={`user-${el.url}`}
              path={el.url}
              element={el.content}
            />
          ))}
        </Routes>
      </Box>
    </Box>
  );
};

export default GroupsGridHeader;
