import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {
  DEFAULT_DATE_FORMAT,
  DateFormat,
  format,
} from '@one-vision/date-utils';
import {
  DEFAULT_INPUT_DATE_MASK,
  DEFAULT_PICKER_VIEWS,
} from 'constants/dates';

interface Props {
  name: string;
  value: Date | string | null;
  error?: string;
  label: string;
  className?: string;
  minDate?: string;
  maxDate?: string;
  defaultCalendarMonth?: string;
  disablePast?: boolean;
  inputFormat?: string;
  mask?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange?: (value: Date | null, inputValue?: string) => void;
  onBlur?: (event: unknown) => void;
  setFieldValue?: (value: Date | string | null) => void;
}

/** This component is created as a fix and based on day-zero DzDateTimePicker */
export const DatePickerInput: React.FC<Props> = ({
  name,
  value,
  error,
  inputFormat = DEFAULT_DATE_FORMAT,
  mask = DEFAULT_INPUT_DATE_MASK,
  setFieldValue,
  label,
  className,
  minDate,
  maxDate,
  defaultCalendarMonth,
  disablePast,
  disabled,
  fullWidth,
  onChange,
  onBlur,
}) => {
  const onDateChange = useCallback(
    (date: Date | null, inputValue?: string) => {
      if (onChange) {
        return onChange(date, inputValue);
      }

      try {
        const sanitizedValue =
          !inputValue || inputValue.length === inputFormat.length
            ? format(date, inputFormat as DateFormat)
            : date;

        setFieldValue?.(sanitizedValue ?? '');
      } catch (error) {
        setFieldValue?.(inputValue ?? '');
      }
    },
    [setFieldValue, onChange, inputFormat],
  );

  return (
    <DatePicker
      renderInput={({ inputProps = {}, value, ...params }) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          name={name}
          label={label}
          error={Boolean(error)}
          helperText={error}
          className={className}
          inputProps={{
            ...inputProps,
            onBlur: (...args) => {
              if (inputProps.onBlur) {
                inputProps.onBlur(...args);
              }
              onBlur?.(...args);
            },
          }}
        />
      )}
      value={dayjs(value)}
      inputFormat={inputFormat}
      mask={mask}
      views={DEFAULT_PICKER_VIEWS}
      onChange={onDateChange}
      minDate={minDate ? new Date(minDate) : undefined}
      maxDate={maxDate ? new Date(maxDate) : undefined}
      defaultCalendarMonth={
        defaultCalendarMonth ? new Date(defaultCalendarMonth) : undefined
      }
      disablePast={disablePast}
      disabled={disabled}
    />
  );
};
