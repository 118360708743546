import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

import {
  ModalManagerProps,
  withModalManager,
} from 'components/modal-manager';
import { CUSTOM_CLASSES } from 'core/theme';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as yup from 'yup';
import { FlatPartnerGroupRuleValue } from 'core/redux/reducers/partnerGroupRuleValues';
import { FlatPartnerGroup } from 'core/redux/reducers/partnerGroups';
import { FlatPartnerGroupRule } from 'core/redux/reducers/partnerGroupRules';
import { FlatUserGroupRuleValue } from 'core/redux/reducers/userGroupRuleValues';
import { FlatUserGroup } from 'core/redux/reducers/userGroups';
import { FlatUserGroupRule } from 'core/redux/reducers/userGroupRules';
import { useDispatch } from 'react-redux';
import { AppDispatch, AppThunk } from 'core/redux';
import { fetchPartnerRuleValue } from 'core/redux/thunks';

type PartnerGroupThunk = (
  {
    id,
    value,
    groupId,
    groupRuleId,
  }: {
    id: FlatPartnerGroupRuleValue['id'];
    value: FlatPartnerGroupRuleValue['value'];
    groupId: string;
    groupRuleId: string;
  },
  listId?: string,
) => AppThunk;

type UserGroupThunk = (
  {
    id,
    value,
    groupId,
    groupRuleId,
  }: {
    id: FlatUserGroupRuleValue['id'];
    value: FlatUserGroupRuleValue['value'];
    groupId: string;
    groupRuleId: string;
  },
  listId?: string,
) => AppThunk;

interface FormikValues {
  group: FlatPartnerGroup | FlatUserGroup | null;
  groupRule: FlatPartnerGroupRule | FlatUserGroupRule | null;
  value: string;
}

export interface UpdateValueDialogProps {
  ruleValue: FlatPartnerGroupRuleValue | FlatUserGroupRuleValue;
  group: FlatPartnerGroup | FlatUserGroup;
  groupRule: FlatPartnerGroupRule | FlatUserGroupRule;
  updateThunk: PartnerGroupThunk | UserGroupThunk;
  id: string;
  groupAutocomplete: (props: FieldProps) => JSX.Element;
  groupRuleAutocomplete: (props: FieldProps) => JSX.Element;
}

const validationSchema = yup.object({
  value: yup.string().required('Field is required'),
  group: yup.object().nonNullable().required('Field is required'),
  groupRule: yup.object().nonNullable().required('Field is required'),
});

export interface FromRuleValues {
  value: string;
  group: FlatPartnerGroup | FlatUserGroup;
  rule: FlatPartnerGroupRule | FlatUserGroupRule;
}

export const UpdateValueDialog: React.FC<
  UpdateValueDialogProps & ModalManagerProps
> = ({
  isOpen,
  close,
  clear,
  groupRule,
  ruleValue,
  group,
  updateThunk,
  id,
  groupAutocomplete,
  groupRuleAutocomplete,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const [isSubmitting, setSubmitionState] = useState(false);
  const onClose = useCallback(() => close(), [close]);

  const handleSubmit = useCallback(
    async (values: FormikValues) => {
      setSubmitionState(true);

      await dispatch(
        updateThunk({
          id,
          groupId: values.group?.id as string,
          groupRuleId: values.groupRule?.id as string,
          value: values.value,
        }),
      );
      await dispatch(fetchPartnerRuleValue(id as string));
      close();
    },
    [setSubmitionState, dispatch, id, close, updateThunk],
  );

  return (
    <Dialog
      className={CUSTOM_CLASSES.DRAWER}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      transitionDuration={400}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'left',
          onExited: clear,
          mountOnEnter: true,
          unmountOnExit: true,
        } as TransitionProps
      }
    >
      <DialogTitle>
        Update rule value
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik<FormikValues>
          initialValues={{
            value: ruleValue.value || '',
            group,
            groupRule,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form
            id="update-value-form"
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
              padding: `${theme.spacing(2)} 0`,
            }}
            role="form"
          >
            <Field name="group">
              {(props: FieldProps) => {
                return groupAutocomplete(props);
              }}
            </Field>
            <Field name="groupRule">
              {(props: FieldProps) => {
                return groupRuleAutocomplete(props);
              }}
            </Field>
            <Field name="value">
              {({ meta, field }: FieldProps<string>) => (
                <TextField
                  id="value"
                  label="Value"
                  {...field}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={
                    meta.touched && meta.error ? meta.error : undefined
                  }
                />
              )}
            </Field>
          </Form>
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          color="primary"
          data-cy="submit-update-value-dialog"
          type="submit"
          form="update-value-form"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withModalManager<UpdateValueDialogProps>()(
  UpdateValueDialog,
);
