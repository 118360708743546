import { UserGroupRId } from '@one-vision/json-api-parser';
import { useAppSelector } from 'core/redux';
import UserGroups, { FlatUserGroup } from 'core/redux/reducers/userGroups';

export function useRelatedUserGroupsSelector({
  /**
   * Plugging in an empty array because of the BE bug.
   * Ticket https://linear.app/onevision-resources/issue/1V-868/jsonapi-relationship-object-should-have-a-relation-field
   */
  userGroup = [],
}: {
  userGroup: UserGroupRId[];
}) {
  const groupIds = userGroup?.length
    ? userGroup.map((group) => group.id as string)
    : [];

  const groups = useAppSelector(
    (state) =>
      UserGroups.selectors.selectByIds(state, groupIds) as FlatUserGroup[],
  );

  return groups;
}
