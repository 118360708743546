import React, { useCallback } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoLetterIcon from './Icons/LogoLetterIcon';
import { PageRoutes } from 'constants/routes';

const AppNavBar: React.FC = () => {
  const { classes } = useStyles();

  const getClassName = useCallback<
    Exclude<NavLinkProps['className'], undefined | string>
  >(
    ({ isActive }) => {
      return `${classes.navLink} ${isActive ? 'is-active' : ''}`;
    },
    [classes.navLink],
  );
  return (
    <nav className={classes.navbar}>
      <NavLink
        to={PageRoutes.HOME}
        className={`${classes.homeLink} ${classes.navLink}`}
      >
        <LogoLetterIcon />
        <span className={classes.navLinkText}>Admin Panel</span>
      </NavLink>
      <NavLink to={PageRoutes.PARTNER_GROUPS} className={getClassName}>
        <SettingsInputComponentIcon />
        <span className={classes.navLinkText}>Partner Groups</span>
      </NavLink>
      <NavLink to={PageRoutes.PARTNERS} className={getClassName}>
        <ManageAccountsIcon />
        <span className={classes.navLinkText}>Partners</span>
      </NavLink>
      <NavLink to={PageRoutes.USER_GROUPS} className={getClassName}>
        <AdminPanelSettingsIcon />
        <span className={classes.navLinkText}>User Groups</span>
      </NavLink>
    </nav>
  );
};

const useStyles = makeStyles()((theme) => ({
  navbar: {
    gridColumn: 'sidebar-start / sidebar-end',
    backgroundColor: theme.palette.black.ovBlack,
    color: theme.palette.black.black5,
    [theme.breakpoints.up('md')]: {
      width: '22rem',
    },
    [theme.breakpoints.down('md')]: {
      width: '4.8rem',
    },
    transition: 'width 0.5s',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none' /* Safari and Chrome */,
    },
    msOverflowStyle: 'none' /* Internet Explorer 10+ */,
    scrollbarWidth: 'none' /* Firefox */,
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(),
    padding: theme.spacing(),
  },
  navLink: {
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: 500,
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
    borderRadius: '4px',
    '&.is-active': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&>.MuiSvgIcon-root': {
      fontSize: '1.6rem',
    },
  },
  homeLink: {
    padding: `${theme.spacing(2)} ${theme.spacing(0.5)}`,
    '&>.MuiSvgIcon-root': {
      fontSize: '2.4rem',
    },
  },
  navLinkText: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    lineHeight: 1,
  },
}));

export default AppNavBar;
