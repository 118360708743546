import { readAsList } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import GroupRules from 'core/redux/reducers/partnerGroupRules';
import PartnerGroups from 'core/redux/reducers/partnerGroups';
import RuleValues from 'core/redux/reducers/partnerGroupRuleValues';

export interface RuleValueFormValues {
  groupId: string | null;
  groupRuleId: string | null;
  value: string;
}

export const createPartnerRuleValue =
  (values: RuleValueFormValues, listId?: string): AppThunk =>
  async (dispatch) => {
    const { groupRuleId, groupId } = values;
    if (!groupRuleId || !groupId) return;

    const data = {
      attributes: {
        value: values.value,
      },
      relationships: {
        partnerGroup: {
          data: { id: groupId },
        },
        partnerGroupRule: {
          data: { id: groupRuleId },
        },
      },
    };

    try {
      await apiClient.postRuleValue(data);
      const apiResponse = await apiClient.getRuleValues({
        filter: {
          partnerGroupId: groupId,
          partnerGroupRuleId: groupRuleId,
        },
        include: ['partnerGroup', 'partnerGroupRule'],
      });

      const { entities } = readAsList(apiResponse.data);

      const { PartnerGroupRuleValue, PartnerGroup, PartnerGroupRule } =
        entities;

      if (PartnerGroupRuleValue) {
        dispatch(
          RuleValues.actions.update(PartnerGroupRuleValue[0], listId),
        );
      }

      if (PartnerGroup) {
        dispatch(PartnerGroups.actions.upsertMany(PartnerGroup));
      }
      if (PartnerGroupRule) {
        dispatch(GroupRules.actions.upsertMany(PartnerGroupRule));
      }
    } catch (error) {
      console.error(error);
    }
  };
