/**
 * Usage:
 * buildQuery({
 *   search: 'hello',
 *   sort: 'createdAt',
 *   include: 'address',
 * }) === '?search=hello&sort=createdAt&include=address';
 *
 * buildQuery({
 *   search: 'world',
 *   page: {
 *     offset: 40,
 *     limit: 20,
 *   },
 * }) === '?search=world&page[offset]=40&page[limit]=20';
 *
 * buildQuery({
 *   include: ['client', 'address'],
 *   filter: {
 *     'address.parternId': 5319,
 *   },
 * }) === '?include=client,address&filter[address.parternId]=5319';
 *
 * buildQuery({
 *   search: null,
 *   include: undefined,
 *   filter: {
 *     'address.parternId': 5319,
 *   },
 * }) === '?search=&filter[address.parternId]=5319';
 */

type QueryArray = Array<string | number | boolean>;

export interface NestedQuery {
  [key: string | number]:
    | string
    | number
    | boolean
    | undefined
    | null
    | QueryArray
    | NestedQuery;
}

function isObject(x: unknown): x is NestedQuery {
  return typeof x === 'object';
}

function isArray(x: unknown): x is QueryArray {
  return Array.isArray(x);
}

/**
 * Turns a nested query object into a flat array of URL parameters
 * @param {string} path - specifies the prefix for nested variable names, e.g filter[name]=tim
 * @param {NestedQuery} obj - object that describes corresponding variable names & property values
 * @param {string[]} flatArr - seed array of previous parameters for recursive calls
 * @returns {string[]} - array of URL parameters to be stitched together
 */
const flatten = (
  path: string,
  obj: NestedQuery,
  flatArr: string[],
): string[] =>
  Object.keys(obj).reduce((fa, property) => {
    let value = obj[property];
    if (value === undefined) {
      return flatArr;
    }
    if (value === null) {
      value = '';
    }
    const encodedProperty = encodeURIComponent(property);
    const newPath = path ? `${path}[${encodedProperty}]` : encodedProperty;
    const theType = typeof value;
    if (theType === 'function') {
      value = '';
    }
    if (isArray(value)) {
      value = value.map(encodeURIComponent).join(',');
      fa.push(`${newPath}=${value}`);
      return fa;
    }
    if (isObject(value)) {
      return flatten(newPath, value, fa);
    }
    fa.push(`${newPath}=${encodeURIComponent(value)}`);
    return fa;
  }, flatArr);

const flat = (obj: NestedQuery) => flatten('', obj, []);

const stringify = (query: NestedQuery) => {
  const queryString = flat(query).join('&');
  return queryString ? `?${queryString}` : '';
};

export const buildQuery = (query: NestedQuery) => {
  if (query == null || typeof query !== 'object') {
    return '';
  }

  return stringify(query);
};
