import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback } from 'react';
import { useModalManager } from './modal-manager';
import { ModalIDs } from 'constants/modals';

const NewPartnerButton: React.FC = () => {
  const { openModal } = useModalManager();
  const handleClick = useCallback(() => {
    openModal(ModalIDs.CreatePartnerDialog, {});
  }, [openModal]);
  return (
    <Button
      startIcon={<AddIcon />}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      New Partner
    </Button>
  );
};

export default NewPartnerButton;
