import { Action, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { Reducer } from '@reduxjs/toolkit';
import { ReducerWithInitialState } from '@reduxjs/toolkit/dist/createReducer';
import { Dictionary } from './types';

interface EntityWithId {
  id: EntityId;
}

type DefaultLookupAction = PayloadAction<EntityWithId>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IdSelector = (action: any) => EntityId;

export const mergePayload = <S extends object, P extends object>(
  state: S | undefined,
  action: PayloadAction<P>,
) =>
  !state
    ? {
        ...action.payload,
      }
    : {
        ...state,
        ...action.payload,
      };

const defaultIdSelector: IdSelector = (action: DefaultLookupAction) =>
  action.payload.id;

const updateIfNeeded = <S>(
  state: Dictionary<S>,
  id: EntityId,
  entity: S,
) => (state[id] !== entity ? { ...state, [id]: entity } : state);

const updateById =
  <S, A extends Action>(
    state: Dictionary<S>,
    action: A,
    entityReducer: Reducer<S, A>,
  ) =>
  (id: EntityId) =>
    id != null
      ? updateIfNeeded(state, id, entityReducer(state[id], action))
      : state;

export const lookupReducer =
  <S, IS extends IdSelector>(
    entityReducer: ReducerWithInitialState<S>,
    idSelector?: IS,
  ) =>
  (
    state: Dictionary<S> | undefined = {},
    action: IS extends (action: infer A) => EntityId
      ? A
      : DefaultLookupAction,
  ) => {
    const id = idSelector ? idSelector(action) : defaultIdSelector(action);

    return updateById(state, action, entityReducer)(id);
  };
