import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Zoom,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  ModalManagerProps,
  withModalManager,
} from 'components/modal-manager';
import { ALLOWED_S3_CONTENT_TYPE } from 'constants/textEditor';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    padding: theme.spacing(1),
  },
  dialogPaper: {
    width: '100%',
    margin: 0,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  dialogActions: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
}));

export interface SelectImageDialogResponse {
  url: string;
  file: File | null;
}

const SelectImageDialog: React.FC<
  ModalManagerProps<SelectImageDialogResponse | undefined>
> = ({ isOpen, close, clear }) => {
  const { classes } = useStyles();

  const [state, setState] = useState<{
    url: string;
    file: File | null;
  }>({
    url: '',
    file: null,
  });

  const closeHandler = useCallback(() => {
    close();
  }, [close]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((state) => ({
        ...state,
        [event.target.name]: event.target.value,
      }));
    },
    [setState],
  );

  const handleSubmit = useCallback(() => {
    close(state);
  }, [state, close]);

  const handleFileSelection = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { target: fileInput } = event;
      if (!fileInput.files?.length) {
        setState((previous) => ({
          ...previous,
          file: null,
        }));
      } else {
        const chosenFile = fileInput.files[0];
        setState((previous) => ({
          ...previous,
          file: chosenFile,
        }));
      }
    },
    [setState],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeHandler}
      closeAfterTransition
      transitionDuration={400}
      TransitionComponent={Zoom}
      TransitionProps={
        {
          onExited: clear,
          mountOnEnter: true,
          unmountOnExit: true,
        } as TransitionProps
      }
      disableRestoreFocus={true}
      classes={{
        container: classes.dialogContainer,
        paper: classes.dialogPaper,
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <TextField
          label="URL"
          name="url"
          margin="none"
          value={state.url}
          onChange={handleChange}
          autoFocus
          disabled={!!state.file}
        />
        <TextField
          label="File"
          InputLabelProps={{
            shrink: true,
          }}
          name="file"
          margin="none"
          InputProps={{
            type: 'file',
            onChange: handleFileSelection,
            inputProps: {
              accept: ALLOWED_S3_CONTENT_TYPE.join(', '),
            },
          }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" size="large" onClick={closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withModalManager()(SelectImageDialog);
