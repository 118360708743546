// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Context } from './types';

const ModalContext = React.createContext<Context>({
  propsDictionary: {},
  openModal: () => new Promise(() => undefined),
  closeModal: () => undefined,
  clearModal: () => undefined,
});

export default ModalContext;
