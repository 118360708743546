import { ColDef } from 'ag-grid-community';
import { AppDispatch, useAppSelector } from 'core/redux';
import RuleValues, {
  FlatUserGroupRuleValue,
} from 'core/redux/reducers/userGroupRuleValues';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppGrid from './AppGrid';
import {
  deleteUserRuleValue,
  fetchUserRuleValues,
  updateUserRuleValue,
} from 'core/redux/thunks';
import { useModalManager } from './modal-manager';
import { ModalIDs } from 'constants/modals';
import { DEFAULT_LIST_ID } from 'constants/redux';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UpdateValueDialogProps } from './modals/UpdateValueDialog';
import { useRelatedUserGroupsSelector } from 'hooks/useRealtedUserGroupsSelector';
import { useRelatedUserRulesSelector } from 'hooks/useRelatedUserRulesSelector';
import UserGroupAutocomplete from './UserGroupAutocomplete';
import { FieldProps } from 'formik';
import UserGroupRuleAutocomplete from './UserGroupRuleAutocomplete';

const groupAutocomplete = ({ field, form, meta }: FieldProps) => (
  <UserGroupAutocomplete
    AutocompleteProps={{
      id: field.name,
      value: field.value,
      isOptionEqualToValue: (option) => {
        return option.id === field.value.id;
      },
      onChange: (_: unknown, value) => {
        if (!value) {
          form.setFieldValue(field.name, null);
          return;
        }

        form.setFieldValue(field.name, value);
      },
    }}
    TextFieldProps={{
      error: Boolean(meta.touched && meta.error),
      helperText: meta.touched && meta.error ? meta.error : undefined,
      onBlur: field.onBlur,
    }}
  />
);
const groupRuleAutocomplete = ({ field, form, meta }: FieldProps) => (
  <UserGroupRuleAutocomplete
    AutocompleteProps={{
      id: field.name,
      value: field.value,
      isOptionEqualToValue: (option) => {
        return option.id === field.value.id;
      },
      onChange: (_: unknown, value) => {
        if (!value) {
          form.setFieldValue(field.name, null);
          return;
        }

        form.setFieldValue(field.name, value);
      },
    }}
    TextFieldProps={{
      error: Boolean(meta.touched && meta.error),
      helperText: meta.touched && meta.error ? meta.error : undefined,
      onBlur: field.onBlur,
    }}
  />
);

const ActionsMenu: React.FC<{
  data: FlatUserGroupRuleValue;
}> = ({ data }) => {
  const buttonRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpenState] = useState(false);
  const { openModal } = useModalManager();
  const handleOpen = useCallback(() => setOpenState(true), [setOpenState]);
  const handleClose = useCallback(
    () => setOpenState(false),
    [setOpenState],
  );

  const ruleValue = useAppSelector((state) =>
    RuleValues.selectors.selectById(state, data.id as string),
  ) as FlatUserGroupRuleValue;

  const [group] = useRelatedUserGroupsSelector(
    ruleValue.relationships || [],
  );
  const [rule] = useRelatedUserRulesSelector(
    ruleValue.relationships || [],
  );

  const handleDelete = useCallback(async () => {
    handleClose();
    const result = await openModal<unknown, boolean>(
      ModalIDs.ConfirmDeletionModal,
      {},
    );

    if (result) {
      dispatch(deleteUserRuleValue({ id: data.id }, DEFAULT_LIST_ID));
    }
  }, [openModal, data, handleClose, dispatch]);
  const handleUpdate = useCallback(async () => {
    handleClose();
    await openModal<UpdateValueDialogProps, void>(
      ModalIDs.UpdateValueDialog,
      {
        ruleValue,
        groupRule: rule,
        group,
        updateThunk: updateUserRuleValue,
        id: data.id as string,
        groupAutocomplete: groupAutocomplete,
        groupRuleAutocomplete: groupRuleAutocomplete,
      },
    );
  }, [handleClose, openModal, ruleValue, rule, group, data]);
  return (
    <>
      <IconButton
        ref={buttonRef}
        aria-haspopup="menu"
        aria-controls="actions-menu"
        aria-expanded={open}
        onClick={handleOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        open={open}
        anchorEl={buttonRef.current}
        onClose={handleClose}
      >
        <MenuItem onClick={handleUpdate}>Update</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

const defaultColDef: ColDef<FlatUserGroupRuleValue> = {
  resizable: true,
  sortable: false,
};

const getColumnDefs = () => {
  const columnDefs: ColDef<FlatUserGroupRuleValue>[] = [
    {
      headerName: 'ID',
      field: 'id',
    },
    {
      headerName: 'Group',
      cellRenderer: function Renderer({
        data,
      }: {
        data: FlatUserGroupRuleValue;
      }) {
        const [group] = useRelatedUserGroupsSelector(data.relationships);

        return group.name;
      },
    },
    {
      headerName: 'Rule',
      cellRenderer: function Renderer({
        data,
      }: {
        data: FlatUserGroupRuleValue;
      }) {
        const [groupRule] = useRelatedUserRulesSelector(
          data.relationships,
        );

        return groupRule.name;
      },
    },
    {
      headerName: 'Value',
      field: 'value',
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
    },
    {
      headerName: 'Updated At',
      field: 'updatedAt',
    },
    {
      headerName: 'Actions',
      cellRenderer: ActionsMenu,
    },
  ];
  return columnDefs;
};

const RuleValuesGrid: React.FC = () => {
  const rowData = useAppSelector(
    (state) =>
      RuleValues.selectors.selectEntitiesFromList(
        state,
        DEFAULT_LIST_ID,
      ) as FlatUserGroupRuleValue[],
  );
  const listState = useAppSelector((state) =>
    RuleValues.selectors.selectList(state, DEFAULT_LIST_ID),
  );
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (rowData.length) {
      return;
    }
    dispatch(
      fetchUserRuleValues(
        { page: 1, include: ['userGroup', 'userGroupRule'] },
        DEFAULT_LIST_ID,
      ),
    );
  }, [dispatch, rowData]);

  return (
    <AppGrid
      rowData={rowData}
      defaultColDef={defaultColDef}
      columnDefs={getColumnDefs()}
      isLoading={!listState.isReady}
    />
  );
};

export default RuleValuesGrid;
