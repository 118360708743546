import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Snackbar as SnackbarComponent,
  Alert,
  SnackbarCloseReason,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { actions, useAppSelector } from 'core/redux';

interface Props {
  position?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
}

export const Snackbar: React.FC<Props> = ({
  position = { vertical: 'bottom', horizontal: 'center' },
}) => {
  const { classes, theme } = useStyles();

  const { text, type } = useAppSelector((state) => state.snackbar);

  const dispatch = useDispatch();

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(actions.closeSnackbar());
  };

  return (
    <SnackbarComponent
      anchorOrigin={position}
      open={Boolean(text)}
      autoHideDuration={theme.transitions.defaultDuration}
      onClose={handleClose}
    >
      <Alert
        classes={{
          message: classes.message,
          filledSuccess: classes.filledSuccess,
          filledError: classes.filledError,
          filledInfo: classes.filledInfo,
        }}
        elevation={theme.transitions.defaultElevation}
        onClose={handleClose}
        severity={type}
      >
        {text}
      </Alert>
    </SnackbarComponent>
  );
};

const useStyles = makeStyles()((theme) => ({
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  filledSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  filledError: {
    backgroundColor: theme.palette.error.main,
  },
  filledInfo: {
    backgroundColor: theme.palette.black.black5,
  },
}));
