import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import { FlatPartnerGroupRuleValue } from 'core/redux/reducers/partnerGroupRuleValues';

export const updatePartnerRuleValue =
  ({
    id,
    value,
    groupId,
    groupRuleId,
  }: {
    id: FlatPartnerGroupRuleValue['id'];
    value: FlatPartnerGroupRuleValue['value'];
    groupId: string;
    groupRuleId: string;
  }): AppThunk =>
  async () => {
    const data = {
      id,
      attributes: {
        value,
      },
      relationships: {
        partnerGroup: {
          data: {
            type: 'PartnerGroup',
            id: groupId,
          },
        },
        partnerGroupRule: {
          data: {
            type: 'PartnerGroupRule',
            id: groupRuleId,
          },
        },
      },
    };
    try {
      await apiClient.patchRuleValue(data);
    } catch (error) {
      console.error(error);
    }
  };
