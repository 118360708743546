import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import { FlatUserGroupRuleValue } from 'core/redux/reducers/userGroupRuleValues';

export const updateUserRuleValue =
  ({
    id,
    value,
    groupId,
    groupRuleId,
  }: {
    id: FlatUserGroupRuleValue['id'];
    value: FlatUserGroupRuleValue['value'];
    groupId: string;
    groupRuleId: string;
  }): AppThunk =>
  async () => {
    const data = {
      id,
      attributes: {
        value,
      },
      relationships: {
        userGroup: {
          data: {
            type: 'UserGroup',
            id: groupId,
          },
        },
        userGroupRule: {
          data: {
            type: 'UserGroupRule',
            id: groupRuleId,
          },
        },
      },
    };
    try {
      await apiClient.patchUserRuleValue(data);
    } catch (error) {
      console.error(error);
    }
  };
