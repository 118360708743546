import React, { useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useModalManager } from './modal-manager';
import { ModalIDs } from 'constants/modals';
import { Button } from '@mui/material';
import { CreateGroupDialogProps } from './modals/CreateGroupDialog';

const NewGroupButton: React.FC<CreateGroupDialogProps> = ({
  idSchema,
  createThunk,
  type,
}) => {
  const { openModal } = useModalManager();
  const handleClick = useCallback(() => {
    openModal<CreateGroupDialogProps, void>(ModalIDs.CreateGroupDialog, {
      idSchema,
      createThunk,
      type,
    });
  }, [openModal, idSchema, createThunk, type]);
  return (
    <Button
      startIcon={<AddIcon />}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      New Group
    </Button>
  );
};

export default NewGroupButton;
