import {
  createAction,
  createReducer,
  AnyAction,
  PayloadAction,
  combineReducers,
  createSelector,
  EntityId,
} from '@reduxjs/toolkit';

import { batchCreate, batchingReducer } from '../reducers/batching';
import { mergePayload, lookupReducer } from '../reducers/handlers';
import { exclude, include, mergeLists } from '../reducers/helpers';
import {
  CreateEntityReducer,
  Dictionary,
  EntitiesPostfix,
  IdentifiableChunk,
  InferReducerState,
  ListReducerState,
} from './types';

interface ListMeta {
  meta:
    | {
        listId: string;
      }
    | undefined;
}

type ListAction = PayloadAction & ListMeta;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface PrepareListAction<P = any> {
  (payload: P, listId?: string): { payload: P } & ListMeta;
}
function isListAction(action: AnyAction): action is ListAction {
  return !!action.meta?.listId;
}

const listPayload: PrepareListAction = (payload, listId?: string) => ({
  payload,
  meta: listId ? { listId } : undefined,
});

export const createEntityReducer: CreateEntityReducer = <
  EntityType,
  EntityIdProperty extends keyof EntityType,
  EntityName extends string,
>({
  entityName,
  entityIdProperty,
  idSelector,
}: {
  entityName: EntityName;
  entityIdProperty: EntityIdProperty;
  idSelector: (
    entityChunk: IdentifiableChunk<EntityType, EntityIdProperty>,
  ) => EntityId;
}) => {
  type EntityChunk = IdentifiableChunk<EntityType, EntityIdProperty>;
  const prefix = `DZ::${entityName.toUpperCase()}`;

  const upsertMany = createAction(
    `${prefix}::UPSERT-MANY`,
    listPayload as PrepareListAction<EntityChunk[]>,
  );
  const update = createAction(
    `${prefix}::UPDATE`,
    listPayload as PrepareListAction<EntityChunk>,
  );
  const remove = createAction(
    `${prefix}::REMOVE`,
    listPayload as PrepareListAction<EntityChunk>,
  );
  const updateList = createAction(
    `${prefix}::UPDATE-LIST`,
    listPayload as PrepareListAction<Partial<ListReducerState>>,
  );
  const reset = createAction(`${prefix}::RESET`);
  const batch = batchCreate(`${prefix}::BATCH`);

  const initialListState: ListReducerState = {
    page: 1,
    perPage: 200,
    lastPage: 1,
    totalCount: 0,
    ids: [],
    isReady: false,
  };

  const entityList = createReducer(initialListState, (builder) => {
    builder
      .addCase(updateList, (state, { payload }) => {
        const page =
          typeof payload.page === 'number' ? payload.page : state.page;

        let ids: EntityId[] = state.ids;

        if (payload.ids) {
          ids =
            page > 1 ? mergeLists(state.ids, payload.ids) : payload.ids;
        }
        return {
          ...state,
          isReady: true,
          ...payload,
          page,
          ids,
        };
      })
      .addCase(update, (state, { payload }) => {
        return {
          ...state,
          ids: include(state.ids, idSelector(payload)),
        };
      })
      .addCase(remove, (state, { payload }) => {
        return {
          ...state,
          ids: exclude(state.ids, idSelector(payload)),
        };
      });
  });

  const lists = createReducer<Dictionary<ListReducerState>>(
    {},
    (builder) => {
      builder.addMatcher(
        isListAction,
        lookupReducer(entityList, (action) => action.meta.listId),
      );
    },
  );

  const initialEntityState: EntityChunk = {
    [entityIdProperty]: '',
  } as EntityChunk;

  const entity = createReducer(initialEntityState, (builder) => {
    builder.addCase(update, mergePayload);
  });

  const initialByIdState: Dictionary<EntityChunk> = {};

  const byId = createReducer(initialByIdState, (builder) => {
    // eslint-disable-next-line
    // @ts-ignore
    builder
      .addCase(
        update,
        lookupReducer(entity, (action) => idSelector(action.payload)),
      )
      .addCase(remove, (lookup, { payload }) => {
        delete lookup[idSelector(payload)];
      })
      .addCase(upsertMany, (lookup, { payload }) => {
        payload.forEach((entity) => {
          // eslint-disable-next-line
          // @ts-ignore
          lookup[idSelector(entity)] = {
            // eslint-disable-next-line
            // @ts-ignore
            ...(lookup[idSelector(entity)] || {}),
            ...entity,
          };
        });
      });
  });

  const singleActionReducer = combineReducers({
    byId,
    lists,
  });

  const reducer = batchingReducer(batch.type, singleActionReducer);

  type ReducerState = InferReducerState<typeof reducer>;

  const domain = (_: {
    [key in EntitiesPostfix<EntityName>]: ReducerState;
  }): ReducerState => _[`${entityName}Entities`];
  const selectAllEntities = createSelector(
    domain,
    (_): Dictionary<EntityChunk> => _?.byId || {},
  );

  const selectAllLists = createSelector(
    domain,
    (_): Dictionary<ListReducerState> => _?.lists || {},
  );
  const selectList = createSelector(
    selectAllLists,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, listId: string) => listId,
    (listsLookup, listId): ListReducerState =>
      listsLookup[listId] || { ...initialListState },
  );
  const selectListReadiness = createSelector(
    selectList,
    (list): boolean => list.isReady,
  );

  const selectById = createSelector(
    selectAllEntities,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, id: string) => id,
    (lookup, id): EntityChunk =>
      lookup[id] || {
        ...initialEntityState,
      },
  );

  const selectByIds = createSelector(
    selectAllEntities,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, ids: string[]) => ids,
    (lookup, ids): EntityChunk[] => {
      return ids.map(
        (id) =>
          lookup[id] || {
            ...initialEntityState,
          },
      );
    },
  );

  const selectEntitiesFromList = createSelector(
    selectList,
    selectAllEntities,
    ({ ids }, lookup) =>
      ids.map(
        (id) =>
          lookup[id] || {
            ...initialEntityState,
          },
      ),
  );
  return {
    reducer,
    actions: {
      update,
      remove,
      updateList,
      reset,
      batch,
      upsertMany,
    },
    selectors: {
      selectAllEntities,
      selectAllLists,
      selectList,
      selectListReadiness,
      selectById,
      selectByIds,
      selectEntitiesFromList,
    },
  };
};
