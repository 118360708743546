import React from 'react';
import { ThemeProvider } from '@mui/material';
import { configureTheme } from 'core/theme';
import { Provider } from 'react-redux';
import { store } from './core/redux';
import { BrowserRouter } from 'react-router-dom';
import Root from 'Root';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ModalProvider } from 'components/modal-manager';

const theme = configureTheme();

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <ModalProvider>
              <Root />
            </ModalProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
