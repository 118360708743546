import React from 'react';
import { DRAWER_WIDTH } from 'constants/style';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import { csn } from '@one-vision/utils';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    width: '0px',
    transition: theme.transitions.create(['width', 'border']),
    border: `none`,
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: `0 4px 4px 0 ${theme.palette.black.black20}`,
    overflow: 'hidden',
    flexShrink: 0,
    marginRight: `-${theme.spacing(1)}`,
  },
  showOnOpened: {
    border: `1px solid ${theme.palette.black.black20}`,
    width: `${DRAWER_WIDTH}`,
    marginRight: `0`,
  },
  drawerPaper: {
    width: `${DRAWER_WIDTH}`,
    position: 'relative',
    height: '100%',
  },
  closeButton: {
    backgroundColor: theme.palette.black.black5,
    position: 'absolute',
    top: '4px',
    left: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: '16px',
    height: '16px',
    cursor: 'pointer',
    borderRadius: '50%',
    zIndex: 10,
    '&:hover': {
      backgroundColor: theme.palette.black.black5,
    },
  },
  closeIcon: {
    height: '12px',
    width: '12px',
    color: theme.palette.black.black50,
  },
  tooltip: {
    fontSize: theme.spacing(1.5),
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  content: JSX.Element;
}

export const Sidebar: React.FC<Props> = ({ open, onClose, content }) => {
  const { classes, theme } = useStyles();

  return (
    <div
      role="dialog"
      className={csn(classes.root, [classes.showOnOpened, open])}
      style={{
        maxHeight: `calc(100vh - ${theme.spacing(2)})`,
      }}
    >
      <div className={classes.drawerPaper}>
        <IconButton
          classes={{ root: classes.closeButton }}
          onClick={onClose}
        >
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Close Side Panel"
          >
            <CloseIcon className={csn(classes.closeIcon)} />
          </Tooltip>
        </IconButton>
        {content}
      </div>
    </div>
  );
};
