import React from 'react';

import { Box } from '@mui/material';
import PartnerGroupsGridHeader from 'components/PartnerGroupsGridHeader';
import { Navigate, Route, Routes } from 'react-router-dom';
import GroupsGrid from 'components/PartnerGroupsGrid';
import GroupRulesGrid from 'components/PartnerGroupRulesGrid';
import RuleValuesGrid from 'components/PartnerRuleValuesGrid';
import { GroupsTabRoutes } from 'constants/routes';

const PartnerGroupsPage: React.FC = () => {
  return (
    <Box
      sx={{ height: 'inherit', display: 'flex', flexDirection: 'column' }}
    >
      <PartnerGroupsGridHeader />
      <Box flex="1 1 auto" position="relative">
        <Routes>
          <Route
            path={`/${GroupsTabRoutes.GROUPS}/*`}
            element={<GroupsGrid />}
          />
          <Route
            path={`/${GroupsTabRoutes.GROUP_RULES}/*`}
            element={<GroupRulesGrid />}
          />
          <Route
            path={`/${GroupsTabRoutes.RULE_VALUES}/*`}
            element={<RuleValuesGrid />}
          />
          <Route
            path="*"
            element={<Navigate to={GroupsTabRoutes.GROUPS} replace />}
          />
        </Routes>
      </Box>
    </Box>
  );
};

export default PartnerGroupsPage;
