import React from 'react';
import ConfirmDeletionModal from './ConfirmDeletionModal';
import { ModalIDs } from 'constants/modals';
import UpdateGroupDialog from './UpdateGroupDialog';
import UpdateRuleDialog from './UpdateRuleDialog';
import UpdateValueDialog from './UpdateValueDialog';
import UpdatePartnerDialog from './UpdatePartnerDialog';
import CreateGroupDialog from './CreateGroupDialog';
import CreateRuleDialog from './CreateRuleDialog';
import CreateValueDialog from './CreateValueDialog';
import CreatePartnerDialog from './CreatePartnerDialog';
import EditLinkDialog from './EditLinkDialog';
import SelectImageDialog from './SelectImageDialog';

const DzModals: React.FC = () => {
  return (
    <>
      <ConfirmDeletionModal id={ModalIDs.ConfirmDeletionModal} />
      <UpdateGroupDialog id={ModalIDs.UpdateGroupDialog} />
      <UpdateRuleDialog id={ModalIDs.UpdateRuleDialog} />
      <UpdateValueDialog id={ModalIDs.UpdateValueDialog} />
      <UpdatePartnerDialog id={ModalIDs.UpdatePartnerDialog} />
      <CreateGroupDialog id={ModalIDs.CreateGroupDialog} />
      <CreateRuleDialog id={ModalIDs.CreateRuleDialog} />
      <CreateValueDialog id={ModalIDs.CreateValueDialog} />
      <CreatePartnerDialog id={ModalIDs.CreatePartnerDialog} />
      <EditLinkDialog id={ModalIDs.EditLink} />
      <SelectImageDialog id={ModalIDs.SelectImage} />
    </>
  );
};

export default DzModals;
