import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SnackbarType = 'success' | 'info' | 'warning' | 'error';

interface SnackbarSliceState {
  text: string;
  type?: SnackbarType;
}
const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    text: '',
    type: undefined,
  } as SnackbarSliceState,
  reducers: {
    updateSnackbar: (
      _: SnackbarSliceState,
      action: PayloadAction<SnackbarSliceState>,
    ): SnackbarSliceState => action.payload,
    closeSnackbar: (state: SnackbarSliceState): SnackbarSliceState => ({
      ...state,
      text: '',
    }),
  },
});

export const snackbar = {
  reducer: {
    [snackbarSlice.name]: snackbarSlice.reducer,
  },
  actions: {
    ...snackbarSlice.actions,
  },
};
