import { readAsList } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import userGroupRules from 'core/redux/reducers/userGroupRules';
import { getNextPage } from 'core/redux/reducers/helpers';
import { ListReducerState } from 'core/redux/reducers/types';

export const fetchUserGroupRules =
  (options: Partial<ListReducerState> = {}, listId?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const listState = userGroupRules.selectors.selectList(
      state,
      listId || '',
    );
    const nextPage = getNextPage(options.page, listState);

    const limit = options.perPage || listState.perPage;
    const offset = limit * (nextPage - 1);

    delete options.perPage;
    const apiResponse = await apiClient.getUserGroupRules({
      include: listState.include,
      search: listState.search,
      ...options,
      page: {
        limit,
        offset,
      },
    });

    const {
      data: {
        meta: { page, defaultPage, ...meta },
      },
    } = apiResponse;

    const { entities } = readAsList(apiResponse.data);

    const { UserGroupRule } = entities;

    if (UserGroupRule) {
      dispatch(
        userGroupRules.actions.batch([
          userGroupRules.actions.updateList(
            {
              ...meta,
              ids: UserGroupRule.map((rule) => rule.id),
              page: page ? page.offset / page.limit + 1 : nextPage,
              perPage: page ? +page.limit : listState.perPage,
              lastPage: Math.ceil(
                meta.totalCount / (page ? page.limit : listState.perPage),
              ),
            },
            listId,
          ),
          userGroupRules.actions.upsertMany(UserGroupRule),
        ]),
      );
    }
  };
