import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PartnerGroupsPage from 'pages/PartnerGroupsPage';
import PartnersPage from 'pages/PartnersPage';
import UserGroupsPage from 'pages/UserGroupsPage';
import { PageRoutes } from 'constants/routes';
import Home from 'components/Home';

const Pages: React.FC = () => {
  return (
    <Routes>
      <Route path={PageRoutes.HOME} element={<Home />} />
      <Route
        path={`/${PageRoutes.PARTNER_GROUPS}/*`}
        element={<PartnerGroupsPage />}
      />
      <Route
        path={`/${PageRoutes.PARTNERS}/*`}
        element={<PartnersPage />}
      />
      <Route
        path={`/${PageRoutes.USER_GROUPS}/*`}
        element={<UserGroupsPage />}
      />
      <Route path="/*" element={<Navigate to={'/'} replace />} />
    </Routes>
  );
};

export default Pages;
