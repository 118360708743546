import { ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Transitions {
    defaultDuration: number;
    defaultElevation: number;
  }

  interface TransitionsOptions {
    defaultDuration: number;
    defaultElevation: number;
  }
}

export const transitions: ThemeOptions['transitions'] = {
  defaultDuration: 7000,
  defaultElevation: 6,
};
