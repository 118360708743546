export enum PageRoutes {
  HOME = '/',
  PARTNER_GROUPS = 'partner-groups',
  PARTNERS = 'partners',
  USER_GROUPS = 'user-groups',
}

export enum GroupsTabRoutes {
  GROUPS = 'groups',
  GROUP_RULES = 'group-rules',
  RULE_VALUES = 'rule-values',
}

export enum UrlParamsName {
  SELECTED = 'selected',
  CURRENT_VIEW = 'view',
}
