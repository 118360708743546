// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useMemo, useReducer } from 'react';
import ModalContext from './modal-context';
import { ModalProps, OpenModal } from './types';
import reducer, { clearProps, setProps } from './reducer';
import { ModalIDs } from 'constants/modals';

const initialState = {};

const ModalProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [propsDictionary, dispatch] = useReducer(reducer, initialState);

  const openModal: OpenModal = useCallback(
    function <InputType, OutputType>(
      modalID: ModalIDs,
      passedProps: InputType,
    ) {
      return new Promise<OutputType | undefined>((resolve) => {
        const modalProps = {
          ...passedProps,
          isOpen: true,
          resolve,
          close: (value: OutputType | undefined) => {
            resolve(value);
            dispatch(setProps(modalID, { isOpen: false }));
          },
          clear: () => {
            dispatch(clearProps(modalID));
          },
          kill: (value?: OutputType | undefined) => {
            resolve(value);
            dispatch(clearProps(modalID));
          },
        } as ModalProps<InputType, OutputType>;

        dispatch(setProps(modalID, modalProps));
      });
    },
    [dispatch],
  );

  const closeModal = useCallback(
    function (modalID: ModalIDs) {
      const modalProps = propsDictionary[modalID] as
        | ModalProps<unknown, void>
        | undefined;
      if (modalProps) {
        const { resolve } = modalProps;
        if (resolve) {
          resolve();
        }
        dispatch(
          setProps(modalID, {
            ...modalProps,
            isOpen: false,
          }),
        );
      }
    },
    [propsDictionary, dispatch],
  );

  const clearModal = useCallback(
    function (modalID: ModalIDs) {
      const modalProps = propsDictionary[modalID] as
        | ModalProps<unknown, void>
        | undefined;
      if (modalProps) {
        const { resolve } = modalProps;
        if (resolve) {
          resolve();
        }
        dispatch(clearProps(modalID));
      }
    },
    [propsDictionary, dispatch],
  );

  const context = useMemo(
    () => ({
      propsDictionary,
      openModal,
      closeModal,
      clearModal,
    }),
    [openModal, closeModal, propsDictionary, clearModal],
  );

  return (
    <ModalContext.Provider value={context}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
