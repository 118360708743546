import { JsonApiId } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import RuleValues from 'core/redux/reducers/userGroupRuleValues';

export const deleteUserRuleValue =
  (options: { id: JsonApiId }, listId?: string): AppThunk =>
  async (dispatch) => {
    try {
      await apiClient.deleteUserRuleValue(options);
      dispatch(RuleValues.actions.remove(options, listId));
    } catch (error) {
      console.error(error);
    }
  };
