// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-case-declarations */
import { ModalIDs } from 'constants/modals';
import {
  ModalProps,
  SetPropsAction,
  ActionTypes,
  ClearPropsAction,
  Actions,
  PropsDictionary,
} from './types';

export const setProps = <InputType, OutputType>(
  modalID: ModalIDs,
  props: Partial<ModalProps<InputType, OutputType>>,
): SetPropsAction<InputType, OutputType> => ({
  type: ActionTypes.setProps,
  payload: { modalID, props },
});

export const clearProps = (modalID: ModalIDs): ClearPropsAction => ({
  type: ActionTypes.clearProps,
  payload: {
    modalID,
  },
});

const reducer = (
  state: PropsDictionary,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: Actions<any, any>,
) => {
  switch (action.type) {
    case ActionTypes.setProps:
      const previousModalProps = state[action.payload.modalID] || {};
      return {
        ...state,
        [action.payload.modalID]: {
          ...previousModalProps,
          ...action.payload.props,
        },
      };
    case ActionTypes.clearProps:
      const newPropsDictionary = { ...state };
      delete newPropsDictionary[action.payload.modalID];
      return newPropsDictionary;
    default:
      return state;
  }
};

export default reducer;
