// @TODO
import React, { JSXElementConstructor, useContext } from 'react';
import ModalContext from './modal-context';
import {
  DistributiveOmit,
  GetLibraryManagedProps,
  GetProps,
  InjectedModalManagerProps,
  Matching,
  Shared,
  WrappedModalComponent,
  ModalProps,
} from './types';
import { ModalIDs } from 'constants/modals';

// eslint-disable-next-line @typescript-eslint/ban-types
const withModalManager = function <InputType>() {
  return function <
    C extends JSXElementConstructor<
      Matching<InputType & InjectedModalManagerProps, GetProps<C>>
    >,
  >(
    WrappedComponent: C,
  ): WrappedModalComponent<
    C,
    DistributiveOmit<
      GetLibraryManagedProps<C>,
      keyof Shared<
        InputType & InjectedModalManagerProps,
        GetLibraryManagedProps<C>
      >
    > & { id: ModalIDs }
  > {
    return Object.assign(
      function WithModalManager({ id, ...props }: { id: ModalIDs }) {
        const { propsDictionary } = useContext(ModalContext);
        const modalProps = propsDictionary[id] as ModalProps<
          InputType,
          unknown
        >;
        if (modalProps) {
          const { resolve: _, ...injectedProps } = modalProps;
          return (
            // eslint-disable-next-line
            // @ts-ignore
            <WrappedComponent id={id} {...props} {...injectedProps} />
          );
        }
        return null;
      },
      {
        displayName: `WithModalManager(${
          (WrappedComponent as React.ComponentType).displayName ||
          WrappedComponent.name ||
          'Component'
        })`,
        WrappedComponent,
      },
    );
  };
};

export default withModalManager;
