import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Slide,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { uploadFile } from 'core/s3';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

import {
  ModalManagerProps,
  withModalManager,
} from 'components/modal-manager';
import { CUSTOM_CLASSES } from 'core/theme';
import { FlatPartnerGroupRule } from 'core/redux/reducers/partnerGroupRules';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as yup from 'yup';
import { RichTextEditor } from 'components/rich-text-editor';
import { replaceNewLineWithParagraph } from 'utils/text';
import { UpdateGroupFormValues } from 'types';
import { IdentifiableChunk } from 'core/redux/reducers/types';
import { FlatUserGroupRule } from 'core/redux/reducers/userGroupRules';
import { AppDispatch, AppThunk } from 'core/redux';
import { useDispatch } from 'react-redux';

type PartnerGroupThunk = (
  updates: Pick<FlatPartnerGroupRule, 'id'> &
    Partial<FlatPartnerGroupRule>,
) => AppThunk;

type UserGroupThunk = (
  updates: Pick<FlatUserGroupRule, 'id'> & Partial<FlatUserGroupRule>,
) => AppThunk;

export interface UpdateRuleDialogProps {
  rule:
    | IdentifiableChunk<FlatPartnerGroupRule, 'id'>
    | IdentifiableChunk<FlatUserGroupRule, 'id'>;
  updateThunk: PartnerGroupThunk | UserGroupThunk;
  id: string;
}

const validationSchema = yup.object({
  name: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
});

export const UpdateRuleDialog: React.FC<
  UpdateRuleDialogProps & ModalManagerProps
> = ({ isOpen, close, clear, rule, updateThunk, id }) => {
  const theme = useTheme();
  const [isSubmitting, setSubmitionState] = useState(false);
  const onClose = useCallback(() => close(), [close]);

  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = useCallback(
    async (values: UpdateGroupFormValues, close: () => void) => {
      if (
        rule.name === values.name &&
        rule.description === values.description
      ) {
        close();
        return;
      }

      setSubmitionState(true);
      await dispatch(
        updateThunk({
          id,
          ...values,
        }),
      );
      close();
    },
    [setSubmitionState, dispatch, updateThunk, rule, id],
  );

  const s3Client = useMemo(() => {
    return {
      uploadFile,
    };
  }, []);

  return (
    <Dialog
      className={CUSTOM_CLASSES.DRAWER}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      transitionDuration={400}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'left',
          onExited: clear,
          mountOnEnter: true,
          unmountOnExit: true,
        } as TransitionProps
      }
    >
      <DialogTitle>
        Update group rule
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik<UpdateGroupFormValues>
          initialValues={{
            name: rule.name || '',
            description: rule.description || '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values, close)}
        >
          <Form
            id="update-rule-form"
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: theme.spacing(2),
              padding: `${theme.spacing(2)} 0`,
            }}
            role="form"
          >
            <Field name="name">
              {({ meta, field }: FieldProps<string>) => (
                <TextField
                  id="name"
                  label="Name"
                  {...field}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={
                    meta.touched && meta.error ? meta.error : undefined
                  }
                />
              )}
            </Field>
            <Field name="description">
              {({ meta, field, form }: FieldProps<string>) => (
                <>
                  <Typography variant="subtitle2">Description</Typography>
                  <RichTextEditor
                    content={replaceNewLineWithParagraph(field.value)}
                    onUpdate={({ editor }) => {
                      const value = editor.getHTML();

                      form.setFieldValue(field.name, value);
                    }}
                    s3Client={s3Client}
                  />
                  {meta.touched && meta.error && (
                    <FormHelperText error={!!meta.error}>
                      {meta.error}
                    </FormHelperText>
                  )}
                </>
              )}
            </Field>
          </Form>
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          color="primary"
          data-cy="submit-update-rule-dialog"
          type="submit"
          form="update-rule-form"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withModalManager<UpdateRuleDialogProps>()(UpdateRuleDialog);
