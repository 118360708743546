import { YES } from 'constants/stringBooleans';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import Partners, { FlatPartner } from 'core/redux/reducers/partners';

export const createPartner =
  (
    newPartnerData: Pick<FlatPartner, 'id'> & Partial<FlatPartner>,
    listId?: string,
  ): AppThunk =>
  async (dispatch) => {
    const result = await apiClient.createPartner(newPartnerData);

    const {
      data: {
        data: { id, type, attributes },
      },
    } = result;

    const partner: FlatPartner = {
      id,
      type,
      relationships: { partnerGroup: [] },
      ...attributes,
    };

    if (partner.currentPartner === YES) {
      dispatch(Partners.actions.update(partner, listId));
    }
  };
