import { readAsList } from '@one-vision/json-api-parser';
import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import GroupRules, {
  FlatPartnerGroupRule,
} from 'core/redux/reducers/partnerGroupRules';

export const createPartnerGroupRule =
  (
    attributes: Pick<FlatPartnerGroupRule, 'name' | 'description' | 'id'>,
    listId?: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const apiResponse = await apiClient.postPartnerGroupRule({
        ...attributes,
        lid: attributes.id,
      });

      const { entities } = readAsList(apiResponse.data);
      const { PartnerGroupRule } = entities;

      if (PartnerGroupRule) {
        dispatch(GroupRules.actions.update(PartnerGroupRule[0], listId));
      }
    } catch (error) {
      console.error(error);
    }
  };
