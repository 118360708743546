import { ModalIDs } from 'constants/modals';
import {
  ClassAttributes,
  ComponentClass,
  JSXElementConstructor,
} from 'react';

export enum ActionTypes {
  initial = 'INITIAL',
  setProps = 'SET_PROPS',
  clearProps = 'CLEAR_PROPS',
}

export interface SetPropsAction<InputType, OutputType> {
  type: ActionTypes.setProps;
  payload: {
    modalID: ModalIDs;
    props: Partial<ModalProps<InputType, OutputType>>;
  };
}

export interface ClearPropsAction {
  type: ActionTypes.clearProps;
  payload: {
    modalID: ModalIDs;
  };
}

export type Actions<InputType, OutputType> =
  | SetPropsAction<InputType, OutputType>
  | ClearPropsAction;

export type OpenModal = <InputType, OutputType>(
  modalID: ModalIDs,
  passedProps: InputType,
) => Promise<OutputType | undefined>;

export type CloseModal = (modalID: ModalIDs) => void;

export type ClearModal = (modalID: ModalIDs) => void;

export interface ModalManagerProps<T = unknown> {
  isOpen: boolean;
  resolve?: (value?: T | PromiseLike<T | undefined>) => void;
  close: (value?: T) => void;
  clear: () => void;
  kill: (value?: T) => void;
}

export type InjectedModalManagerProps = Omit<ModalManagerProps, 'resolve'>;

export type ModalProps<InputType, OutputType> = InputType &
  ModalManagerProps<OutputType>;

export type PropsDictionary = Record<ModalIDs, ModalManagerProps>;

export interface Context {
  propsDictionary: PropsDictionary;
  openModal: OpenModal;
  closeModal: CloseModal;
  clearModal: ClearModal;
}

// Infers prop type from component C
export type GetProps<C> = C extends JSXElementConstructor<infer P>
  ? C extends ComponentClass<P>
    ? ClassAttributes<InstanceType<C>> & P
    : P
  : never;

// Applies LibraryManagedAttributes (proper handling of defaultProps
// and propTypes).
export type GetLibraryManagedProps<C> = JSX.LibraryManagedAttributes<
  C,
  GetProps<C>
>;

export type DistributiveOmit<T, K extends keyof T> = T extends unknown
  ? Omit<T, K>
  : never;

/**
 * A property P will be present if:
 * - it is present in DecorationTargetProps
 *
 * Its value will be dependent on the following conditions
 * - if property P is present in InjectedProps and its definition extends the definition
 *   in DecorationTargetProps, then its definition will be that of DecorationTargetProps[P]
 * - if property P is not present in InjectedProps then its definition will be that of
 *   DecorationTargetProps[P]
 * - if property P is present in InjectedProps but does not extend the
 *   DecorationTargetProps[P] definition, its definition will be that of InjectedProps[P]
 */
export type Matching<InjectedProps, DecorationTargetProps> = {
  [P in keyof DecorationTargetProps]: P extends keyof InjectedProps
    ? InjectedProps[P] extends DecorationTargetProps[P]
      ? DecorationTargetProps[P]
      : InjectedProps[P]
    : DecorationTargetProps[P];
};

/**
 * a property P will be present if :
 * - it is present in both DecorationTargetProps and InjectedProps
 * - InjectedProps[P] can satisfy DecorationTargetProps[P]
 * ie: decorated component can accept more types than decorator is injecting
 *
 * For decoration, inject props or ownProps are all optionally
 * required by the decorated (right hand side) component.
 * But any property required by the decorated component must be satisfied by the injected property.
 */
export type Shared<InjectedProps, DecorationTargetProps> = {
  [P in Extract<
    keyof InjectedProps,
    keyof DecorationTargetProps
  >]?: InjectedProps[P] extends DecorationTargetProps[P]
    ? DecorationTargetProps[P]
    : never;
};

export type WrappedModalComponent<C, P> = JSXElementConstructor<P> & {
  WrappedComponent: C;
};
