import { ListReducerState } from './types';

export const include = <T>(set: T[] = [], item?: T) =>
  item == null || set.includes(item) ? set : [...set, item];

export const removeArrItem = <T>(arr: T[], index: number) => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1),
];

export const exclude = <T>(set: T[] = [], item?: T) => {
  if (item == null) {
    return set;
  }
  const index = set.indexOf(item);
  if (index < 0) return set;
  return removeArrItem(set, index);
};

export const mergeLists = <T>(list1: T[], list2: T[]) =>
  list2.reduce((list, item) => include(list, item), list1);

export const getNextPage = (
  requestedPage: number | undefined,
  { page, perPage, totalCount }: ListReducerState,
): number =>
  requestedPage || (page * perPage > totalCount ? page : page + 1);
