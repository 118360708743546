import { Action, AnyAction, Reducer } from '@reduxjs/toolkit';

export interface BatchActionCreator<T extends string> {
  <A extends AnyAction>(actions: A[]): A | BatchAction<T, A>;
  type: T;
}

export interface BatchAction<
  T extends string = string,
  A extends AnyAction = Action,
> extends Action<T> {
  actions: A[];
}

const createBatchActionGuard = (type: string) => {
  function isBatchAction(action: AnyAction): action is BatchAction {
    return action.type === type;
  }

  return isBatchAction;
};

export const batchCreate = <T extends string>(
  type: T,
): BatchActionCreator<T> =>
  Object.assign(
    <A extends AnyAction>(actions: A[]) =>
      actions && actions.length === 1 ? actions[0] : { type, actions },
    { type },
  );

const INIT = {
  type: '@@OV-FRNT-BATCH::INIT::' + Math.random().toString().slice(2),
};

export const batchingReducer =
  <S, A extends Action<string>, BT extends string>(
    type: BT,
    reducer: Reducer<S, A>,
  ) =>
  (state: S | undefined, action: A | BatchAction<BT, A>) => {
    if (!createBatchActionGuard(type)(action)) {
      return reducer(state, action);
    }

    if (action.actions.length > 0) {
      return action.actions.reduce(reducer, state);
    }

    return reducer(state, INIT as A);
  };
