import { ThemeOptions } from '@mui/material';

export const typography: ThemeOptions['typography'] = {
  h1: {
    fontSize: '5.2rem',
  },
  h2: {
    fontSize: '4.4rem',
  },
  h3: {
    fontSize: '3.2rem',
  },
  h4: {
    fontSize: '2.6rem',
    fontWeight: 500,
  },
  h5: {
    fontSize: '2rem',
  },
  h6: {
    fontSize: '1.8rem',
  },
  body1: {
    fontSize: '1.6rem',
  },
  body2: {
    fontSize: '1.4rem',
  },
  subtitle1: {
    fontSize: '1.6rem',
  },
  subtitle2: {
    fontSize: '1.4rem',
    fontWeight: 500,
  },
};
