import { apiClient } from 'core/api';
import { AppThunk } from 'core/redux';
import PartnerGroups from 'core/redux/reducers/partnerGroups';

export const deletePartnerGroup =
  (options: { id: string }, listId?: string): AppThunk =>
  async (dispatch) => {
    try {
      await apiClient.deletePartnerGroup(options);
      dispatch(PartnerGroups.actions.remove(options, listId));
    } catch (error) {
      console.error(error);
    }
  };
