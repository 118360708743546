import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Zoom,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from 'tss-react/mui';
import {
  withModalManager,
  ModalManagerProps,
} from 'components/modal-manager';

const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    padding: theme.spacing(1),
  },
  dialogPaper: {
    width: '100%',
    margin: 0,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  dialogActions: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
}));

export interface EditLinkDialogProps {
  url?: string;
  text?: string;
  textInput?: boolean;
}

const EditLinkDialog: React.FC<
  EditLinkDialogProps & ModalManagerProps<EditLinkDialogProps | undefined>
> = ({ isOpen, url = '', text = '', textInput = true, close, clear }) => {
  const { classes } = useStyles();

  const [state, setState] = useState({
    url,
    text,
  });

  const closeHandler = useCallback(() => {
    close();
  }, [close]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((state) => ({
        ...state,
        [event.target.name]: event.target.value,
      }));
    },
    [setState],
  );

  const handleSubmit = useCallback(() => {
    close(state);
  }, [state, close]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeHandler}
      closeAfterTransition
      transitionDuration={400}
      TransitionComponent={Zoom}
      TransitionProps={
        {
          onExited: clear,
          mountOnEnter: true,
          unmountOnExit: true,
        } as TransitionProps
      }
      disableRestoreFocus={true}
      classes={{
        container: classes.dialogContainer,
        paper: classes.dialogPaper,
      }}
    >
      <DialogContent className={classes.dialogContent}>
        {textInput && (
          <TextField
            label="Text"
            name="text"
            margin="none"
            value={state.text}
            onChange={handleChange}
            autoFocus
          />
        )}
        <TextField
          label="URL"
          name="url"
          margin="none"
          value={state.url}
          onChange={handleChange}
          autoFocus={!textInput}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" size="large" onClick={closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withModalManager<EditLinkDialogProps>()(EditLinkDialog);
